.container {
  display: flex;
  flex-direction: row;
  flex: 1;
  height: 100%;
}

.content {
  display: flex;
  width: 100%;
  height: 100%;
  flex: 1;
  color: var(--text-color-rgb);
}

.loader-container {
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  justify-content: center;
  align-items: center;
}

.whiteboard {
  position: fixed;
  z-index: 1000;
  width: 100%;
  height: 100%;
  display: flex;
}

.hidden {
  display: none;
}

.tlui-slider>span {
  transform: none !important;
}

.tlui-slider__thumb {
  max-width: 18px !important;
}

.react-datetime-picker input {
  font-family: "Manrope" !important;
  font-weight: 700 !important;
}

.react-datetime-picker span {
  font-family: "Manrope" !important;
  font-weight: 700 !important;
}

.react-calendar {
  font-family: "Manrope" !important;
  border: 1px solid rgba(var(--text-color-rgb), 12%) !important;
  border-radius: 12px !important;
  padding: 4px;
}

.react-calendar__tile {
  border-radius: 12px;
  font-weight: 500 !important;
}

.react-calendar__tile--now {
  background: rgba(var(--primary-rgb), 24%) !important;
}

.react-calendar__tile--active {
  background: var(--primary) !important;
}

.react-datetime-picker__clock {
  display: none
}

.react-datetime-picker__clear-button {
  display: none
}

.react-datetime-picker__calendar {
  z-index: 9999;
}

.loader {
  --accent-color: var(--primary-color);
  width: 18px;
  height: 18px;
  border-radius: 50%;
  display: inline-block;
  border-top: 3px solid var(--accent-color);
  border-right: 3px solid transparent;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

.splide {
  display: flex !important;
  flex-direction: column !important;
  width: 100% !important;
}

.splide__arrows {
  display: none !important;
}

.splide__pagination {
  position: relative !important;
  padding: .5rem 0 !important;
  bottom: unset !important;
}

.splide__pagination__page.is-active {
  background: var(--primary) !important;
}

.easepick-wrapper {
  display: flex !important;
  justify-content: center !important;
}

.media-controls-container {
  display: none;
}

.mdxeditor-toolbar {
  background-color: var(--background-secondary-color) !important;
  border-radius: 0 !important;
  border-bottom: 1px solid rgba(var(--text-color-rgb), 12%)
}

._mdxeditor-inline {
  width: 100%;
}

._mdxeditor-inline .mdxeditor-toolbar {
  background-color: transparent !important;
  padding: 0;
  font-size: .875rem;
  display: flex;
  justify-content: flex-start;
  z-index: 0;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@media screen and (max-width: 768px) {
  .container {
    flex-direction: column;
  }
}
