.container {
    background-color: var(--background-color);
    border: 1px solid rgba(var(--text-color-rgb), 12%);
    border-radius: 16px;

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    gap: 1rem;
    padding: 1rem;
    color: var(--text-color);

    position: relative;
}

.material {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 40%;
}

.name {
    font-weight: 700;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
    max-height: 3.6em;
    overflow: hidden;
    text-overflow: ellipsis;
    overflow-wrap: anywhere;
}

.size {
    font-weight: 700;
    color: rgba(var(--text-color-rgb), 48%)
}

.lessonName {
    display: flex;
    flex-grow: 1;
    justify-content: center;
    align-items: center;
    gap: .3rem;
    color: rgba(var(--text-color-rgb), 88%);
    text-decoration: underline;
    font-weight: 700;
    white-space: nowrap;
}

.icon {
    width: 1rem;
    height: 1rem;
}

.time {
    display: flex;
    align-items: center;
    gap: .3rem;
    color: rgba(var(--text-color-rgb), 32%);
    font-size: .875rem;
    font-weight: 700;
    white-space: nowrap;
    justify-content: flex-end;
}

.thumbnail {
    border-radius: 6px;
    height: 56px;
    cursor: pointer;
}

.menu {
    display: flex;
    align-items: center;
}


.dialog {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    overflow-y: visible;
    height: 100%;
    max-height: 60vh;
    padding: 1.5rem;
    background-color: white;
}

.right {
    display: flex;
    flex-direction: row;
    gap: .5rem;
    align-items: center;
    justify-content: flex-start;
    flex-grow: 1;
    /* padding-right: 32px; */
}

.row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

@media (max-width: 900px) {
    .container {
        flex-direction: column;
    }

    .material,
    .name,
    .size {
        width: 100%;
    }

    .right {
        padding: 0;
    }

    .lessonName {
        justify-content: flex-start;
    }

    .menu {
        top: 12px;
    }
}


@media screen and (max-width: 540px) {
    .row {
        flex-wrap: wrap;
        gap: .25rem;
    }

    .material {
        gap: .25rem;
    }
}