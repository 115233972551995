.container {
  display: flex;
  flex-direction: column;
  border: 1px solid rgba(var(--text-color-rgb), 12%);
  border-radius: 16px;
  background-color: var(--background-color);
  overflow: hidden;
  height: 100%;
  cursor: pointer;
  transition: all .2s ease;
}

.header {
  border-bottom: 1px solid rgba(var(--text-color-rgb), 12%);
  padding: 0.5rem 1rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.row {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-items: center;
  gap: 1rem;
  row-gap: 1rem;
}

.leftHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: .5rem;
}

.name {
  display: flex;
  align-items: center;
  font-weight: 800;
  cursor: pointer;
}

.description {
  font-weight: 500;
  font-size: 0.875rem;
  color: rgba(var(--text-color-rgb), 88%);
}

.content {
  padding: 0.5rem 1rem;
  padding-bottom: 1.5rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.labeled {
  display: flex;
  flex-direction: column;
  gap: .2rem;
  justify-content: center;
}

.label {
  font-weight: 500;
  font-size: 0.875rem;
  color: rgba(var(--text-color-rgb), 40%);
}

.value {
  font-weight: 600;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: nowrap;
  gap: .2rem;
}

.value>svg {
  width: 16px;
  height: 16px;
}

@media (hover:hover) {
  .container:hover {
    border: 1px solid var(--primary);
  }
}
