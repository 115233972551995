.container {
    display: flex;
    width: 100%;
    padding: 1rem;
    position: relative;
    border: 1px solid rgba(var(--text-color-rgb), 12%);
    border-radius: 12px;
    font-size: 0.75rem;
    font-weight: 600;
    justify-content: space-between;
    align-items: center;
    overflow: hidden;
    gap: 0.25rem;
}

.file {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    gap: 0.25rem;
    flex: 1; /* Permette al contenitore di file di adattarsi */
    min-width: 0; /* Importante per abilitare l'overflow del fileName */
}

.fileName {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    line-clamp: 1;
    -webkit-box-orient: vertical;
    max-height: 2rem;
    overflow: hidden;
    text-overflow: ellipsis;
    flex: 1; 
    overflow-wrap: anywhere;
}

.fileSize {
    white-space: nowrap;
    flex-shrink: 0; /* Impedisce che la dimensione venga ridotta */
}

.progress {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    background-color: rgba(var(--primary-rgb), 20%);
    transition: 0.2s;
    z-index: 0;
}

.deleteIcon {
    width: 16px;
    height: 16px;
    object-fit: contain;
    color: var(--sf-red);
}

.delete {
    padding: .25rem;
    border-radius: 4px;
    cursor: pointer;
    z-index: 1;
}
