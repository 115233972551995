.container {
  display: flex;
  flex-direction: column;
  border: 1px solid rgba(var(--text-color-rgb), 12%);
  width: 100%;
  align-items: center;
  justify-content: center;
  background-color: var(--background-color);
  border-radius: 12px;
  padding: 1.25rem 1rem;
  gap: 1rem;
}

.picture {
  width: 72px;
  height: 72px;
  border-radius: 72px;
  border: 1.8px solid var(--secondary);
  padding: 4px;
}

.name {
  font-weight: 700;
  font-size: 1rem;
  color: #000000;
  text-align: center;
}

.chat {
  min-width: 24px;
  min-height: 24px;
  width: 24px;
  height: 24px;
  cursor: pointer;
}

@media(hover:hover) {
  .chat:hover {
    color: var(--primary)
  }
}
