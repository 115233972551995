.container{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    min-height: 100vh;
    margin: 0 auto;
    background-color: var(--background-secondary-color);
}

.section {
    background-color: var(--background-secondary-color);
    display: flex;
    flex-direction: column;
    width: 100%;
    height: fit-content;
    align-items: center;
    padding: 2rem 0;
}

.sectionInner {
    display: flex;
    flex-direction: row;
    width: 100%;
    max-width: 1440px;
    padding: 0 3rem;
    flex-wrap: wrap;
}

.day {
    display: flex;
    width: 100%;
    flex-direction: column;
    padding: 1rem 0;
    gap: .5rem;
}

.date {
    font-size: 1rem;
    font-weight: 800;
    color: rgba(var(--text-color-rgb), 40%);
}

.notification {
    display: flex;
    flex-direction: row;
    background-color: white;
    padding: 1rem;
    width: 100%;
    border-radius: 1rem;
    align-items: center;
    gap: 1rem;
    position: relative;
}

.notificationBackground {
    display: flex;
    flex-direction: row;
    width: 100%;
    border-radius: 1rem;
    padding-left: .3rem;
    background-color: white;
    box-shadow: 0px 0px 1px 1px rgba(var(--text-color-rgb), 12%);
}

.notificationBackground.read {
    
}

.thumbnail {
    width: 70px;
    height: 40px;
    min-width: 70px;
    min-height: 40px;
    border-radius: 4px;
    object-fit: cover;
}

.info {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

.title {
    font-weight: 700;
    font-size: 1rem;
    color: rgba(var(--text-color-rgb), 56%)
}

.message {
    font-weight: 800;
    font-size: 1.125rem;
    color: var(--text-color);
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    max-height: 3.6em;
    overflow: hidden;
    text-overflow: ellipsis;
}

.message.read {
    color: rgba(var(--text-color-rgb), 40%);
    font-weight: 500;
}

.time {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.3rem;
    font-weight: 600;
    font-size: 1rem;
    color: rgba(var(--text-color-rgb), 40%);
}

@media screen and (max-width: 768px) {

    .section {
        padding: 0;
    }

    .sectionInner {
        padding: 2rem;
        flex-direction: column;
    }

}

@media screen and (max-width: 540px) {

    .sectionInner {
        padding: 1rem;
    }

    .notification {
        border-radius: 0.875rem;
        padding: 0.5rem 1rem;
        padding-bottom: 1.2rem;
    }

    .message {
        font-size: 1rem;
    }

    .time {
        position: absolute;
        bottom: .2rem;
        right: .5rem;
        font-size: 0.875rem;
    }

    .time > svg {
        width: 12px;
        height: 12px;
        max-width: 12px;
        max-height: 12px;
    }

}