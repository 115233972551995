.container {
  display: flex;
  flex-direction: column;
  border: 1px solid rgba(var(--text-color-rgb), 12%);
  border-radius: 16px;
  background-color: var(--background-color);
  overflow: hidden;
  height: 100%;
  cursor: pointer;
  transition: all .2s ease;
}

.header {
  border-bottom: 1px solid rgba(var(--text-color-rgb), 12%);
  padding: 0.5rem 1rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
}

.row {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-items: center;
  gap: 1rem;
  row-gap: 1rem;
}

.leftHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: .5rem;
}

.name {
  display: flex;
  align-items: center;
  font-weight: 800;
  cursor: pointer;
}

.description {
  font-weight: 500;
  font-size: 0.875rem;
  color: rgba(var(--text-color-rgb), 88%);
}

.content {
  padding: 0.5rem 1rem;
  padding-bottom: 1.5rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  flex-grow: 1;
}


.labeled {
  display: flex;
  flex-direction: column;
  gap: .2rem;
  justify-content: center;
}

.label {
  font-weight: 500;
  font-size: 0.875rem;
  color: rgba(var(--text-color-rgb), 40%);
}

.lesson {
  font-weight: 700;
  text-decoration: underline solid 1px rgba(var(--text-color-rgb), 88%);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: nowrap;
  gap: .2rem;
}

.lesson>svg {
  width: 16px;
  height: 16px;
}


.questions {
  font-weight: 600;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: nowrap;
  gap: .2rem;
}


.tags {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: .5rem
}

.statusBadge {
  font-weight: 800;
  font-size: .75rem;
  padding: 2px 8px;
  border: 1.5px solid var(--color);
  color: var(--color);
  border-radius: 6px;
}

.badges {
  display: flex;
  flex-direction: row;
  gap: 0.5rem
}

.button {
  flex-grow: 1;
  display: flex;
  align-items: flex-end;
}

@media screen and (max-width: 1024px) {
  .leftHeader {
    flex-direction: column-reverse;
    align-items: flex-start;
  }
}

@media (hover:hover) {
  .container:hover {
    border: 1px solid var(--primary);
  }
}
