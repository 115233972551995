.container{
	display: flex;
	border-radius: 8px;
	border: 1.5px solid rgba(var(--text-color-rgb), 12%);
	width: fit-content;
	align-items: center;
	justify-content: center;
	gap: .3rem;
	padding: 0 .5rem;
	font-size: 0.75rem;
	font-weight: 800;
	color: #DA3030;
}

.onair {
	width: 8px;
	height: 8px;
	background-color: #DA3030;
	border-radius: 20px;
	animation:onair_wave 2s ease-out infinite;
	position: relative;
}

@keyframes onair_wave {
	0% {
		box-shadow: 0 0 0 0 #DA3030;
		
	}
	100% {
	    box-shadow: 0 0 0 8px rgba(218, 48, 48, 0);
	}
};