.container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
}

.cardContent,
.innerCard {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: .75rem;
  flex: 1 1;
  min-height: auto;
  overflow: visible;
}

.innerCard {
  gap: .2rem;
}


.sectionTitle {
  font-size: .75rem;
  font-weight: 600;
  color: rgba(var(--text-color-rgb), 40%);
}

.addAnswer {
  text-align: center;
  background-color: transparent;
  padding: 1rem;
  color: var(--sf-light-green);
  text-decoration: underline 1px solid var(--sf-light-green);
  font-weight: 800;
  cursor: pointer;
}
