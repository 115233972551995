.container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.section {
    padding: 1rem;
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: .5rem;
}

.cardContent,
.innerCard,
.innerCard2 {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: .75rem;
    flex: 1 1;
    min-height: auto;
    overflow: visible;
}

.innerCard {
    gap: 0;
}

.innerCard2 {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.cardLabel {
    font-weight: 600;
    color: rgba(var(--text-color-rgb), 40%);
    white-space: nowrap;
}

.textAreaContainer {
    display: flex;
    flex-direction: row;
    width: 100%;
}

.textArea {
    border: none;
    padding: .5rem 0;
    width: 100%;
    border-radius: 14px;
    background-color: transparent;
    resize: none;
    font-family: "Manrope";
    font-size: 1rem;
    font-weight: 600;
    flex: 1 1;
    background-color: transparent;
    color: var(--text-color)
}

.textArea:focus {
    outline: none;
}

.textArea::placeholder {
    color: rgba(var(--text-color-rgb), 12%)
}

.divider {
    height: 1px;
    width: 100%;
    border-bottom: 1px solid rgba(var(--text-color-rgb), 12%);
    margin: 1rem 0;
}

.addQuestion {
    text-align: center;
    background-color: rgba(var(--tertiary-rgb), 12%);
    padding: 1.5rem;
    border-radius: 14px;
    border: 1px solid rgba(var(--text-color-rgb), 12%);
    color: var(--tertiary);
    text-decoration: underline 1px solid var(--tertiary);
    font-weight: 800;
    cursor: pointer;
}

.actionsContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap: .5rem;
    padding: 1rem 0;
    align-items: center;
}

.actions {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    gap: .5rem;
    padding: 1rem 0;
}

.date {
    font-weight: 700;
    font-size: 1rem;
    color: rgba(var(--text-color-rgb), 72%);
    text-decoration: underline 0.1px solid;
    display: flex;
    align-items: center;
    gap: .5rem;
}

.validationError {
    color: rgba(var(--secondary-rgb), 88%);
}

.threshold {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    user-select: none;
}

.threshold .values {
    font-weight: 600;
    font-size: .875rem;
    display: flex;
    flex-direction: row;
    gap: .2rem;
    align-items: center;
    color: rgba(var(--text-color-rgb), 40%);
}

.threshold .values b {
    font-weight: 700;
    font-size: 1rem;
    color: rgba(var(--text-color-rgb), 100%);
}

.successButton {
    width: 24px;
    height: 24px;
    padding: 2px;
    border: 1px solid rgba(var(--text-color-rgb), 10%);
    border-radius: 7.5px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    color: var(--tertiary);
    transition: all .2s ease;
}

.testSettings {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    row-gap: .5rem;
    align-items: stretch;
}

.testSetting {
    flex-basis: 50%;
}

.testSetting:nth-child(2n+1) {
    padding-right: .5rem;
}

@media(hover:hover) {
    .successButton:hover {
        border: 1.5px solid var(--tertiary)
    }
}


@media (max-width: 768px) {

    .testSettings {
        flex-direction: column;
        flex-wrap: nowrap;
    }

    .testSetting {
        padding-right: 0 !important;
    }

    .actionsContainer {
        flex-direction: column;
    }

    .actions {
        justify-content: center;
    }

}