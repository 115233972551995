.noWrapper {}

.wrapper {
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  background-color: white;
  overflow: hidden;
  border: 1px solid rgba(var(--text-color-rgb), 12%);
  margin: 1rem 0;
  padding: 1rem;
}

.locked {
  filter: grayscale(1);
}

.container {
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  background-color: var(--background-color);
  overflow: hidden;
  border: 1px solid rgba(var(--text-color-rgb), 12%);
  margin: 1rem 0;
  width: 100%;
}

.thumbnail {
  display: flex;
  width: 100%;
  height: 200px;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
}

.thumbnail>img {
  display: flex;
  object-fit: cover;
  width: 100%;
}

.metas {
  position: absolute;
  bottom: .5rem;
  left: 1rem;
  color: white;
  display: flex;
  flex-direction: row;
  gap: 1rem;
}

.meta {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.2rem;
  font-weight: 600;
  font-size: 0.8rem;
}

.meta>svg>path {
  fill: white;
}

.info {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  gap: .5rem;
}

.description {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  max-height: 3.6em;
  /* (line-height * numero di righe) + eventuali padding/margini */
  overflow: hidden;
  text-overflow: ellipsis;
}

.divider {
  height: 1px;
  width: 100%;
  background-color: rgba(var(--text-color-rgb), 12%);
}

.spacer {
  display: flex;
  flex: 1;
}

.purchase {
  padding: 1rem;
}

.discount {
  display: flex;
  flex-direction: row;
  color: #FF8652;
  font-weight: 500;
  justify-content: center;
  padding-bottom: .5rem;
}

.discount>div {
  text-decoration: line-through;
  padding-left: .3rem;
  font-weight: 700;
}

@media screen and (max-width: 540px) {
  .container {
    margin: 1rem auto;
  }
}
