.cardHeader {
  display: flex;
  flex-direction: row;
  width: 100%;
  border-bottom: 1px solid rgba(var(--text-color-rgb), 12%);
  padding: 1rem 1.5rem;
  align-items: center;
  gap: .7rem;
}

.icon {
  width: 32px;
  height: 32px;
  min-width: 32px;
  min-height: 32px;
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon.exercise {
  background-color: #FFF8DF;
  color: var(--sf-yellow);
}

.info {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.title {
  font-size: 1rem;
  font-weight: 700;
  color: var(--text-color)
}

.subtitle {
  font-size: 0.875rem;
  font-weight: 500;
  color: rgba(var(--text-color-rgb), 72%);
  display: flex;
  align-items: center;
  gap: .2rem;
}

.clockIcon {
  height: 12px;
  width: 12px;
}

.clockIcon>g>path {
  stroke: rgba(var(--text-color-rgb), 72%)
}

.date {
  font-size: 0.875rem;
  font-weight: 700;
  color: rgba(var(--text-color-rgb), 32%);
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  white-space: nowrap;
  gap: 0.4rem;
}

.date>.clockIcon {
  width: 20px;
  height: 20px;
}

.date>.clockIcon>g>path {
  stroke: rgba(var(--text-color-rgb), 32%);
}

.content {
  padding: 1.5rem;
  padding-top: .5rem;
  display: flex;
  flex-direction: column;
  gap: .5rem;
}

.description {
  font-size: 0.875rem;
  font-weight: 500;
  padding-top: 1rem;
  color: var(--text-color);
}

.description.communication {
  padding: 0;
}

.dropzoneContainer {
  display: flex;
  flex-direction: column;
}

.dropzone {
  background-color: rgba(var(--tertiary-rgb), 8%);
  border: 2px dashed #4CB4FF32;
  border-radius: 12px;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: .5rem;
  flex: 1;
  margin-top: 1rem;
}

.dropzoneLabel {
  font-family: var(--primary-font);
  font-weight: 500;
  font-size: 1rem;
  color: rgba(var(--text-color-rgb), 72%);
  text-align: center;
}

.filesContainer {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.files {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-basis: 50%;
  flex-wrap: wrap;
  justify-content: space-between;
  row-gap: .5rem;
}


.file {
  flex-basis: 50%;
  min-width: 256px;
}

.file:nth-child(2n+1) {
  padding-right: .5rem;
}

.addFile {
  display: flex;
  align-items: center;
  gap: .3rem;
  font-weight: 700;
  padding-top: .5rem;
}

.submit {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: .5rem;
}

.submit>.error {
  text-align: end;
  color: var(--secondary);
  font-weight: 600;
}

.loaderContainer {
  width: 100%;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.submitsContainer {
  display: flex;
  flex-direction: column;
  gap: .5rem;
  padding-top: 1rem;
}

.helperText {
  font-weight: 600;
}

.commentContainer {
  display: flex;
  flex-direction: column;
  gap: 2px;
  padding-top: 1rem;
}

.commentHeader {
  border-radius: 12px 12px 0 0;
  padding: 0.625rem 1rem;
  background-color: rgba(var(--text-color-rgb), 4%);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.teacher {
  display: flex;
  flex-direction: row;
  gap: .5rem;
  align-items: center;
}

.teacherPicture {
  width: 32px;
  height: 32px;
  border-radius: 100px;
  object-fit: cover;
  border: 2px solid var(--secondary);
  padding: 2px;
}

.teacherName {
  font-size: .875rem;
  font-weight: 600;
}

.teacherName b {
  font-weight: 800;
}

.time {
  font-size: .875rem;
  font-weight: 700;
  color: rgba(var(--text-color-rgb), 32%);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: .5rem;
}

.clock {
  width: 20px;
  height: 20px;
}

.arrow {
  color: var(--text-color);
  width: 16px;
  height: 16px;
  margin-left: 8px;
  cursor: pointer;
}

.arrow.up {
  transform: rotate(0);
}

.arrow.down {
  transform: rotate(180deg);
}

.extended {
  padding: .625rem 1rem;
  display: flex;
  flex-direction: column;
  gap: .5rem;
  background-color: rgba(var(--text-color-rgb), 4%);
  border-radius: 0 0 12px 12px;
}

.comment {
  font-size: .875rem;
  font-weight: 500;
  text-align: start;
}

@media screen and (max-width: 1280px) {
  .file {
    flex-basis: 100%;
  }

  .file:nth-child(2n+1) {
    padding: 0;
  }
}
