.container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    min-height: 70vh;
    margin: 0 auto;
    background-color: var(--background-secondary-color);
}

.section {
    background-color: var(--background-secondary-color);
    display: flex;
    flex-direction: column;
    width: 100%;
    height: fit-content;
    align-items: center;
    padding: 2rem 0;
}

.sectionInner {
    display: flex;
    flex-direction: row;
    width: 100%;
    max-width: 1440px;
    padding: .5rem 3rem;
    flex-wrap: wrap;
}

.videoPlaceholder {
    width: 100%;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--background-secondary-color);
}

.videoPlaceholder>* {
    display: flex;
    width: 100%;
    height: 100%;
}

.lessonDetails {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
}

.lessonTime {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: .3rem;
    color: rgba(var(--text-color-rgb), 0.64);
}

.lessonHeader {
    display: flex;
    flex-direction: column;
    gap: .75rem;
    width: 100%;
}

.lessonTitle {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    gap: 1rem;
}


.programContainer {
    display: flex;
    padding: 1rem 0;
    width: 100%;
}

.materials {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 1rem;
    padding: 1rem 0;
    height: fit-content;
}

.title {
    width: 100%;
}

.spacer {
    display: flex;
    flex: 1;
}

.noLesson {
    display: flex;
    height: 100%;
    width: 100%;
    min-height: 500px;
    justify-content: center;
    align-items: center;
    background-color: #171717;
    border-radius: 1rem;
    padding: 2rem;
}

.students {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
    padding: 1rem 0;
}

.selectedTest {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 1rem;
    padding: 1rem 0;
}

.tests {
    display: flex;
    width: 100%;
    justify-content: flex-start;
    align-items: flex-end;
    height: 420px;
    position: relative;
}

.testsTrack::-webkit-scrollbar{
    display: none;
}

.testsTrack{
    display: flex;
    flex-direction: row;
    gap: 1rem;
    position: absolute;
    overflow: scroll;
    left: 0;
    right: 0;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.selectedTestCard {
    display: flex;
    border: 1px solid rgba(var(--text-color-rgb), 0.12);
    border-radius: 1.1rem;
    width: fit-content;
    padding: 1rem;
    gap: 1rem;
}

.testInfo {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
    width: 300px;
    border: 1px solid rgba(var(--text-color-rgb), 0.12);
    padding: 1rem;
    border-radius: 1rem;
}

.testInfoTitle {
    display: flex;
    flex-direction: row;
    font-size: 1rem;
    font-weight: 700;
    justify-content: space-between;
}

.testInfoStudents {
    display: flex;
    flex-direction: column;
    gap: .5rem;
    overflow: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.userRow {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 1rem;
    font-family: var(--primary-font);
    font-weight: 600;
    align-items: center;
    padding: .5rem;
    border-radius: 1rem;
    border: 1px solid rgba(var(--text-color-rgb), 0.12);
}

.userRow > img {
    width: 40px;
    height: 40px;
    border-radius: 40px;
    object-fit: cover;
    display: flex;
}

.userRow.passed {
    background-color: rgba(var(--primary-rgb), 20%);
}

.userRow.failed {
    background-color: rgba(var(--secondary-rgb), 20%);
}

.socketReconnecting {
    top: 1rem;
    left: 60%;
    height: auto;
    width: fit-content;
    align-items: center;
    gap: 1rem;
    max-width: 420px;
    transform: translateX(-50%);
    z-index: 999;
    display: flex;
    flex-direction: row;
    position: fixed;
    background-color: rgb(254, 236, 238);
    padding: 1rem;
    border-radius: 1rem;
    border: 1px solid rgba(var(--text-color-rgb), 0.12);
    font-weight: 600;
}

.socketReconnectingTitle {
    font-weight: 700;
    color: var(--sf-red);
}

.socketReconnectingDescription {
    font-size: 0.75rem;
}

.playerWrapper {
    display: flex;
    border-radius: 12px;
    overflow: hidden;
    height: fit-content;
    width: 100%;
    height: 100%;
    aspect-ratio: 16 / 9;
}

@media screen and (max-width: 768px) {

    .section {
        padding: 0;
    }

    .sectionInner {
        padding: 2rem;
        flex-direction: column;
    }

    .lessonTitle {
        flex-direction: column;
        align-items: flex-start;
        gap: .5rem;
    }

    .material {
        max-width: none;
        max-height: none;
    }
}

@media screen and (max-width: 540px) {

    .sectionInner {
        padding: 1rem;
    }

    .material {
        width: 100%;
    }

}