.container {
  display: flex;
  flex-direction: row;
  gap: .625rem;
  padding: .875rem 1.25rem;
  border-radius: 2rem;
  background-color: var(--background-color);
  font-family: 'Manrope';
  font-weight: 800;
  font-size: 1rem;
  min-width: fit-content;
  cursor: pointer;
  transition: all .1s ease;
}

@media screen and (max-width: 540px) {
  .container {
    justify-content: center;
    align-items: center;
    padding: .5rem;
  }
}