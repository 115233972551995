.list {
    display: flex;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    height: 100%;
    background-color: rgba(var(--text-color-rgb), 88%);
    color: var(--background-color);
    overflow: hidden;
    padding: 1rem 0;
    flex-direction: column;
    gap: 0.5rem;
    border-radius: 12px 0 0 12px;
    z-index: 2;
    box-sizing: border-box;
    width: 0;
    transition: all .1s ease-in-out;
}

.list.open {
    width: fit-content;
    padding: 1rem;
    transition: all .1s ease-in-out;
}

.list.sideBySide, .list.open.sideBySide {
    position: relative;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 1rem;
}

.waiting, .presents, .absents {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 95%;
    gap: .5rem;
}

.waitingHeader, .presentsHeader, .absentsHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.waitingParticipants, .presentsParticipants, .absentsParticipants {
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 1rem 0;
    gap: .5rem;
    overflow-y: scroll;
}

.waitingParticipants::-webkit-scrollbar {
    display: none;
}

.presentsParticipants::-webkit-scrollbar {
    display: none;
}

.waitingParticipant, .presentParticipant, .absentParticipant {
    background-color: rgba(var(--background-color-rgb), 10%);
    border-radius: 8px;
    padding: .5rem 1rem;
    font-weight: 600;
    font-size: 1rem;
    display: flex;
    align-items: center;
    gap: 1rem;
}

.waitingAvatar, .presentAvatar {
    width: 40px;
    height: 40px;
    min-width: 40px;
    min-height: 40px;
    border-radius: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 700;
    object-fit: cover;
}

.participantName {
    display: flex;
    width: 100%;
}

.pinBadge {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: .5rem;
    background-color: var(--sf-yellow);
    position: absolute;
    top: .5rem;
    left: .5rem;
    padding: .25rem .5rem;
    border-radius: 8px;
    font-size: .75rem;
    font-weight: 700;
    color: white;
}

.pinBadge>svg {
    width: 12px;
    height: 12px;
    min-width: 12px;
    min-height: 12px;
}

.screenSharingRequest {
    background-color: rgba(var(--background-color-rgb), 10%);
    border-radius: 8px;
    padding: .5rem 1rem;
    font-weight: 600;
    font-size: 1rem;
    display: flex;
    align-items: center;
    gap: 1rem;
}

.requestAvatar {
    width: 40px;
    height: 40px;
    min-width: 40px;
    min-height: 40px;
    border-radius: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 700;
    object-fit: cover;
}

.requestName {
    display: flex;
    width: 100%;
}

.requestIcon {
    display: flex;
    height: 16px;
    width: 16px;
    min-height: 16px;
    min-width: 16px;
}

.declineButton {
    padding: .5rem;
    border-radius: 50px;
    background-color: var(--sf-red);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.acceptButton {
    padding: .5rem;
    border-radius: 50px;
    background-color: var(--sf-green);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.acceptPinButton {
    padding: .5rem;
    border-radius: 50px;
    background-color: var(--sf-yellow);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}