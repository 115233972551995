
.notificationImage {
    display: flex;
    max-width: 150px;
    max-height: 150px;
    object-fit: contain;
}

.title {
    font-size: 1.25rem;
    font-weight: 800;
    text-align: center;
}

.message {
    padding: 1rem 0rem 2rem 0rem;
    text-align: center;
    font-size: 0.875rem;
}

.caption {
    font-size: 0.875rem;
    font-weight: 400;
    text-transform: uppercase;
    text-align: center;
    opacity: 0.56;
    padding-bottom: 1rem;
}