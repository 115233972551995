.header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
}

.leftHeader {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    align-items: center;
}

.validationError {
    color: rgba(var(----sf-red-rgb), 88%);
}

.testType {
    max-width: fit-content;
}

.closeButton {
    padding: .3rem;
    border: 1px solid rgba(var(--text-color-rgb), 10%);
    width: 2rem;
    height: 2rem;
    border-radius: 10px;
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    color: var(----sf-red)
}

.cardContent,
.innerCard {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: .75rem;
    flex: 1 1;
    min-height: auto;
    overflow: visible;
}


@media (max-width: 768px) {

    .leftHeader {
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
    }
}