.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  margin: 0 auto;
  background-color: var(--background-secondary-color);
}

.section {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: fit-content;
  align-items: center;
  padding: 2rem 0;
}

.sectionInner {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1440px;
  padding: 0 3rem;
  gap: 1rem;
}

.content {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.header {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  align-items: center;
  width: 100%;
}

.add {
  flex-grow: 1;
  display: flex;
  justify-content: flex-end;
}

.dialog {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  overflow-y: scroll;
  overflow-x: hidden;
  height: 100%;
  max-height: 60vh;
  padding: 1.5rem;
  background-color: white;
}

.blueButton {
  background-color: var(--tertiary);
}

.dropzoneContainer {
  display: flex;
  flex-direction: column;
}

.dropzone {
  background-color: rgba(var(--tertiary-rgb), 8%);
  border: 2px dashed #4CB4FF32;
  border-radius: 12px;
  padding: 2rem 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  flex: 1;
  margin-top: 1rem;
}

.dropzoneLabel {
  font-family: var(--primary-font);
  font-weight: 500;
  font-size: 1rem;
  color: rgba(var(--text-color-rgb), 72%);
  text-align: center;
}

.filesContainer {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding-top: 1rem;
}

.files {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 1rem;
}

.file {
  width: 100%;
}

.subtitle {
  display: flex;
  align-items: center;
  gap: .3rem;
  font-weight: 700;
}

.materials {
  display: flex;
  flex-direction: column;
}

.module {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
  background-color: white;
}

.moduleTitle {
  display: flex;
  flex-direction: column;
}

.loader {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 220px;
}

@media (hover: hover) {


  .blueButton:hover {
    color: var(--tertiary);
    background-color: var(--background-color);
    box-shadow: inset 0px 0px 0px 2px var(--tertiary);
    transition: .2s ease-in-out all;
  }

  .blueButton:hover>svg>g>path {
    fill: var(--tertiary);
    stroke-width: 0;
  }

}

@media screen and (max-width: 1024px) {
  .file {
    max-width: none;
  }

  .action {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }
}

@media (max-width: 768px) {
  .header {
    flex-direction: column;
  }

  .sectionInner {
    padding: 0 2rem;
  }
}

@media screen and (max-width: 540px) {
  .sectionInner {
    padding: 1rem;
  }
}
