/* From Uiverse.io by arghyaBiswasDev */
/* The switch - the box around the slider */
.toggle {
    font-size: 17px;
    position: relative;
    display: inline-block;
    width: 3em;
    height: 1.5em;
}

/* Hide default HTML checkbox */
.toggle input {
    opacity: 0;
    width: 0;
    height: 0;
}

/* The slider */
.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #fff;
    border: 1px solid #adb5bd;
    transition: .4s;
    border-radius: 30px;
}

.slider:before {
    position: absolute;
    content: "";
    height: 1em;
    width: 1em;
    border-radius: 20px;
    left: 0.27em;
    bottom: 0.2em;
    background-color: #adb5bd;
    transition: .4s;
}

.toggleInput:disabled+.slider {
    background-color: #adb5bd;
    border: 1px solid #adb5bd;
    cursor: not-allowed;
}

.toggleInput:checked+.slider {
    background-color: var(--primary);
    border: 1px solid var(--primary);
}

.toggleInput:focus+.slider {
    box-shadow: 0 0 1px var(--primary);
}

.toggleInput:checked+.slider:before {
    transform: translateX(1.4em);
    background-color: #fff;
}