.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  margin: 0 auto;
  background-color: var(--background-secondary-color);
}

.section {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: fit-content;
  align-items: center;
  padding: 2rem 0;
}

.sectionInner {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1440px;
  padding: 0 3rem;
  gap: 1rem;
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.title {
  font-size: 2rem;
  font-weight: 800;
}

.actions {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
}

.actionIcon {
  width: 16px;
  height: 16px;
}

.top {
  padding: 1rem;
  border-bottom: 1px solid rgba(var(--text-color-rgb), 12%);
  display: flex;
  flex-direction: row;
  gap: .5rem;
  align-items: center;
  font-weight: 700;
}

.labeled {
  display: flex;
  flex-direction: column;
  gap: .2rem;
  justify-content: center;
}

.label {
  font-weight: 500;
  font-size: 0.875rem;
  color: rgba(var(--text-color-rgb), 40%);
}

.value {
  font-weight: 600;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: nowrap;
  gap: .2rem;
}

.cardContent {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1.5rem;
}

.bottomCard {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 80%;
}

.description {
  font-weight: 500;
  font-size: .875rem;
}

.icon {
  border-radius: 32px;
  width: 32px;
  height: 32px;
  background-color: var(--background-color-yellow);
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon>svg {
  width: 20px;
  height: 20px;
  color: var(--sf-yellow);
}

.studentFilesContainer {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.studentFiles {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-basis: 50%;
  flex-wrap: wrap;
  justify-content: space-between;
  row-gap: .5rem;
}


.studentFile {
  flex-basis: 50%;
  min-width: 256px;
}

.studentFile:nth-child(2n+1) {
  padding-right: .5rem;
}


.dialog {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: .7rem;
  padding: .2rem 1rem;
  overflow-y: scroll;
}

.textAreaContainer {
  display: flex;
  flex-direction: row;
}

.textArea {
  border: none;
  padding: .5rem 0;
  width: 100%;
  border-radius: 14px;
  background-color: transparent;
  resize: none;
  font-family: "Manrope";
  font-size: 1rem;
  font-weight: 600;
  flex: 1 1;
  background-color: transparent;
  color: var(--text-color)
}

.textArea:focus {
  outline: none;
}

.textArea::placeholder {
  color: rgba(var(--text-color-rgb), 12%)
}

.dropzoneContainer {
  display: flex;
  flex-direction: column;
}

.dropzone {
  background-color: rgba(var(--tertiary-rgb), 8%);
  border: 2px dashed #4CB4FF32;
  border-radius: 12px;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: .5rem;
  flex: 1;
  margin-top: 1rem;
}

.dropzoneLabel {
  font-family: var(--primary-font);
  font-weight: 500;
  font-size: 1rem;
  color: rgba(var(--text-color-rgb), 72%);
  text-align: center;
}

.filesContainer {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding-top: 1rem;
}

.files {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 1rem;
}

.file {
  width: 100%;
}

.subtitle {
  display: flex;
  align-items: center;
  gap: .3rem;
  font-weight: 700;
}
