.container {
    display: flex;
    padding: .3rem .7rem;
    text-transform: uppercase;
    border-radius: 8px;
    width: fit-content;
    align-items: center;
    gap: .2rem;
    user-select: none;
    white-space: nowrap;
}

.icon {
    cursor: pointer;
    color: #8D8D8D;
    width: 10px;
    height: 10px;
}
