.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  margin: 0 auto;
  background-color: var(--background-secondary-color);
}

.section {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: fit-content;
  align-items: center;
  padding: 2rem 0;
}

.sectionInner {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 900px;
  padding: 0 3rem;
  gap: 1.25rem;
}

.navBarContainer {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  flex-wrap: wrap;
}

.back {
  font-size: 0.875rem;
  font-weight: 800;
  color: var(--primary);
  cursor: pointer;
  width: fit-content;
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: .4rem;
}

.sectionName {
  color: rgba(var(--text-color-rgb), 64%);
  font-size: 1.5rem;
}

.moduleName {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
  flex-wrap: wrap;
}

.unavailable {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  gap: 1rem;
  align-items: center;
  height: 60dvh;
}

@media screen and (max-width: 540px) {

  .section {
    padding: 1rem;
  }

  .sectionInner {
    padding: 0rem;
  }

  .navBarContainer {
    flex-wrap: nowrap;
    overflow-x: scroll;
  }

  .navBarContainer::-webkit-scrollbar{
    display: none;
  }
}