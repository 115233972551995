.container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 30%);
    z-index: 999;
}

.card {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #FFFFFF;
    border-radius: 12px;
    width: 50%;
    max-height: 90vh;
    /* gap: 1rem; */
    transition: all .3s ease;
}

.header {
    padding: 1.5rem;
    display: flex;
    flex-direction: row;
    gap: 2rem;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid rgba(var(--text-color-rgb), 12%);
    background-color: var(--background-color);
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
}

.header.noHeaderStyle {
    padding-bottom: 0;
    border-bottom: none;
    background-color: transparent;
}

.title {
    font-size: 1.5rem;
    font-weight: 800;
    color: var(--text-color);
}

.closeButton {
    padding: .3rem;
    border: 1px solid rgba(var(--text-color-rgb), 10%);
    aspect-ratio: 1/1;
    border-radius: 10px;
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.content {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-height: 60vh;
    align-items: center;
    /* overflow: hidden; */
}

.content>.StripeElement {
    width: 100%;
}

.action {
    justify-self: flex-end;
    background-color: var(--background-color);
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    padding: 1.5rem;
    border-top: 1px solid rgba(var(--text-color-rgb), 12%);
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
}

.icon {
    width: 144px;
    height: 144px;
}

.resultContainer {
    padding-bottom: 1.5rem;
    width: 192px;
    height: 192px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.error {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
}

@media screen and (max-width: 768px) {

    .card {
        /* width: 80%; */
        max-width: 90vw;
    }

    .content::-webkit-scrollbar {
        display: none;
    }

}

@media screen and (max-width: 540px) {

    .card {
        width: 90%;
        max-height: 90vh;
        min-width: 0;
    }

}
