.dialog {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: .7rem;
  padding: 1rem;
}

.textAreaContainer {
  display: flex;
  flex-direction: row;
}

.textArea {
  border: none;
  padding: .5rem 0;
  width: 100%;
  border-radius: 14px;
  resize: none;
  font-family: "Manrope";
  font-size: 1rem;
  font-weight: 600;
  flex: 1 1;
  background-color: transparent;
  color: var(--text-color)
}

.textArea:focus {
  outline: none;
}

.textArea::placeholder {
  font-family: var(--primary-font);
  font-weight: 600;
  opacity: 40%;
  font-size: 1rem;
}

.blueButton {
  background-color: var(--tertiary);
}

.dropzoneContainer {
  display: flex;
  flex-direction: column;
}

.dropzone {
  background-color: rgba(var(--tertiary-rgb), 8%);
  border: 2px dashed #4CB4FF32;
  border-radius: 12px;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: .5rem;
  flex: 1;
  margin-top: 1rem;
}

.dropzoneLabel {
  font-family: var(--primary-font);
  font-weight: 500;
  font-size: 1rem;
  color: rgba(var(--text-color-rgb), 72%);
  text-align: center;
}

.filesContainer {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding-top: 1rem;
}

.files {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 1rem;
}

.file {
  width: 100%;
}

.subtitle {
  display: flex;
  align-items: center;
  gap: .3rem;
  font-weight: 700;
}

.editor {
  width: 100%;
  border-radius: 12px;
  border: 1px solid rgba(var(--text-color-rgb), 12%);
}

.editorContent {
  padding: 1rem;
  max-height: 320px;
  overflow: scroll;
}