.container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    min-height: 100vh;
    margin: 0 auto;
    background-color: var(--background-secondary-color);
}

.section {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: fit-content;
    align-items: center;
    padding: 2rem 0;
}

.sectionInner {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 1440px;
    padding: 0 3rem;
    gap: 1rem;
}

.content {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.header {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    align-items: center;
    width: 100%;
}

.add {
    flex-grow: 1;
    display: flex;
    justify-content: flex-end;
}

.subtitle {
    display: flex;
    align-items: center;
    gap: .3rem;
    font-weight: 700;
}

.modules {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.moduleTitle {
    display: flex;
    flex-direction: column;
  }

.tests {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: stretch;
    gap: 1rem;
}

.test {
    flex-basis: 33.33%;
    flex-grow: 1;
    min-width: 300px;
}

.module {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
    background-color: white;
}


@media (max-width: 768px) {
    .header {
        flex-direction: column;
    }

    .sectionInner {
        padding: 0 2rem;
    }
}

@media screen and (max-width: 540px) {
    .section {
        padding: 0;
    }

    .sectionInner {
        padding: 1rem;
    }
}