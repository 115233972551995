.dummy {
    
}

.header {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
}

.arrows {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    width: fit-content;
    gap: .5rem;
}

.arrowButton {
    aspect-ratio: 1;
    background-color: transparent;
    border: 1px solid rgba(var(--text-color-rgb), 12%);
    border-radius: 20px;
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.arrowButton:disabled > svg > path {
    stroke: rgba(var(--text-color-rgb), 12%);
}

.arrowButton > svg > path {
    stroke: var(--text-color);
}

.arrowButton.left{
    transform: rotate(-90deg);
}

.arrowButton.left > svg {
    transform: translateY(-.5px);
    width: 12px;
    height: 12px;
    min-width: 12px;
    min-height: 12px;
}

.arrowButton.right{
    transform: rotate(90deg);
}

.arrowButton.right > svg {
    transform: translateY(-.5px);
    width: 12px;
    height: 12px;
    min-width: 12px;
    min-height: 12px;
}

.content {
    display: flex;
}

#lessonCarousel {
    display: flex;
    width: 100%;
}

.lessons {
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: center;
    height: fit-content;
}

.lessonCard.slideLeft {
    animation: slide-left 1s ease-in-out;
    animation-fill-mode: forwards;
    animation-delay: .2s;
}

.lessonCard.slideRight {
    animation: slide-right 1s ease-in-out;
    animation-fill-mode: forwards;
    animation-delay: .2s;
}

.lessonCard {
    width: 25%;
    padding: .5rem;
}

.noLessons, .noCourses {
    display: flex;
    width: 100%;
    flex-direction: column;
    font-size: 1rem;
    color: rgba(var(--text-color-rgb), 64%);
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 3rem 0;
    gap: 1rem;
}

.loaderContainer{
    display: flex;
    height: 200px;
    width: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    position: relative;
}

.lessonSlide {
    container: slide / inline-size;
}

@media screen and (max-width: 1440px) {
    
    .lessonCard {
        width: 33%;
    }

}


@media screen and (max-width: 768px) {
    
    .lessonCard {
        width: 50%;
    }

    .section{
        padding: 2rem 0;
    }

    .sectionInner{
        padding: 0 2rem;
    }

}

@media screen and (max-width: 540px) {
    
    .lessonCard {
        width: 100%;
    }

    .section{
        padding: 0;
    }

    .sectionInner{
        padding: 1rem;
    }

    #lessonCarousel {
        max-width: calc(100vw - 4rem);
    }
}


@keyframes slide-left {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(-110%);
    }
}

@keyframes slide-right {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(110%);
    }
}

