.container {
    display: flex;
    min-width: 220px;
    width: 220px;
    min-height: 120px;
    height: 120px;
    border-radius: 12px;
    overflow: hidden;
    position: relative;
    cursor: pointer;
}

.screenShareBadge {
    display: flex;
    height: 24px;
    width: 24px;
    color: white;
    background-color: #4bc9a3;
    border-radius: 12px;
    justify-content: center;
    align-items: center;
    padding: .25rem;

    position: absolute;
    top: 50%;
    right: .5rem;
    transform: translateY(-50%);
    cursor: pointer;
}

.micBadge {
    display: flex;
    height: 24px;
    width: 24px;
    background-color: rgba(var(--background-color-rgb), 10%);
    border-radius: 12px;
    justify-content: center;
    align-items: center;

    position: absolute;
    bottom: .5rem;
    right: .5rem;
}

.micBadge>svg {
    height: 16px;
    width: 16px;
    min-width: 16px;
    min-height: 16px;
}

.micBadge.disabled {
    background-color: #F43A3A;
}

.micBadge.disabled>svg>path {
    fill: var(--background-color);
}

.settingsBadge {
    display: flex;
    height: 24px;
    width: 24px;
    background-color: rgba(var(--text-color-rgb), 100%);
    border-radius: 12px;
    justify-content: center;
    align-items: center;

    position: absolute;
    top: .5rem;
    right: .5rem;
    cursor: pointer;
    transition: all .1s ease-in-out;
    color: white;
}

.settingsBadge>svg {
    height: 16px;
    width: 16px;
    min-width: 16px;
    min-height: 16px;
}

.settingsBadge:hover {
    background-color: rgba(var(--primary-rgb), 100%);
    /* color: rgba(var(--text-color-rgb), 88%); */
    transition: all .1s ease-in-out;
}

.pinBadge {
    display: flex;
    height: 24px;
    width: 24px;
    background-color: rgba(var(--text-color-rgb), 100%);

    border-radius: 12px;
    justify-content: center;
    align-items: center;

    position: absolute;
    top: .5rem;
    left: .5rem;
    cursor: pointer;
    transition: all .1s ease-in-out;
    color: white;
}

.pinBadge>svg {
    height: 16px;
    width: 16px;
    min-width: 16px;
    min-height: 16px;
}

.pinBadge:hover {
    background-color: rgba(var(--sf-yellow-rgb), 100%);
    transition: all .1s ease-in-out;
}

.pinBadge.pinned {
    color:rgba(var(--background-color-rgb), 100%);
    background-color: var(--sf-yellow);
}

.pinBadge.pinned:hover {
    color: var(--sf-yellow);
    background-color: rgba(var(--background-color-rgb), 100%);
}

.pinIcon.pinned {
    color: var(--sf-yellow);
}

.pinIcon:hover {
    color: var(--sf-yellow);
}

.name {
    position: absolute;
    font-family: var(--primary-font);
    font-weight: 600;
    font-size: 0.75rem;
    color: var(--background-color);
    padding: .5rem 1rem;
    bottom: .15rem;
    left: .15rem;
    background-color: rgba(0, 0, 0, 64%);
    border-radius: 8px;
}

.handRaised {
    display: flex;
    position: absolute;
    top: 50%;
    left: 50%;
    font-size: 2rem;
    animation: scaleUpDown 1s linear infinite alternate-reverse;
}

.overlay {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(var(--text-color-rgb), 80%);
    padding: .5rem;
    justify-content: space-between;
}

.overlayHeader {
    display: flex;
    width: 100%;
    color: white;
    font-weight: 700;
    justify-content: space-between;
    align-items: center;
    padding: .125rem .25rem;
}

.overlayContent {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: .5rem;
}

.settingsAction {
    display: flex;
    background-color: rgba(var(--background-color-rgb), 25%);
    border-radius: 12px;
    padding: .5rem;
    justify-content: flex-start;
    align-items: center;
    height: fit-content;
    font-weight: 700;
    font-size: .75rem;

    position: relative;
    cursor: pointer;
    transition: all .1s ease-in-out;
    color: white;
    cursor: pointer;
    border: 1px solid transparent;
}

.settingsAction>svg {
    height: 16px;
    width: 16px;
    min-width: 16px;
    min-height: 16px;
}

.settingsAction:hover {
    transition: all .1s ease-in-out;
    background-color: rgba(var(--text-color-rgb), 100%);
    border: 1px solid rgba(var(--background-color-rgb), 100%);
}


.settingsAction.disabled {
    cursor: not-allowed;
    background-color: rgba(var(--background-color-rgb), 25%);
}

.settingsAction.disabled:hover {
    cursor: not-allowed;
    color: white;
}

.actionLabel {
    display: flex;
    overflow: hidden;
    width: fit-content;
    white-space: nowrap;
    padding-left: .25rem;
    opacity: 0.5;
}

.settingsAction:hover>.actionLabel {
    opacity: 1;
}

.settingsAction.disabled:hover>.actionLabel {
    opacity: 0.5;
}

.listContainer {
    background-color: rgba(var(--background-color-rgb), 10%);
    border-radius: 8px;
    padding: .5rem 1rem;
    font-weight: 600;
    font-size: 1rem;
    display: flex;
    align-items: center;
    gap: 1rem;
    justify-content: space-between;
    flex-wrap: wrap;
}

.listUser {
    display: flex;
    align-items: center;
    gap: .5rem;
}

.listActions {
    display: flex;
    gap: .5rem;
    align-items: center;
}

.listAvatar {
    width: 40px;
    height: 40px;
    min-width: 40px;
    min-height: 40px;
    border-radius: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 700;
    object-fit: cover;
}

.listName {
    display: flex;
    width: 100%;
}

.listContainer .micButton,
.cameraButton,
.screenButton, .pinButton {
    cursor: pointer;
}


.pinButton > svg {
    height: 20px;
    width: 20px;
}


.micButton > svg {
    height: 20px;
    width: 20px;
}

.cameraButton > svg {
    height: 20px;
    width: 20px;
}

.screenButton > svg {
    height: 20px;
    width: 20px;
}

.screenButton {
    opacity: 0.8;
}

.audioGradient {
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 12px;
    pointer-events: none;
    transition: all .3s ease-in-out;
}

@media (hover:hover) {
    .screenButton:hover {
        opacity: 1;
        color: #F43A3A;
    }


    .settingsBadge.sharing:hover {
        mix-blend-mode: normal;
    }
}


@keyframes scaleUpDown {
    0% {
        transform: translate(-50%, -50%) scale(100%);
    }

    100% {
        transform: translate(-50%, -50%) scale(150%);
    }
}