.back {
  font-size: 0.875rem;
  font-weight: 800;
  color: var(--primary);
  cursor: pointer;
  width: fit-content;
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: .4rem;
}
