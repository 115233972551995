.container {
    display: flex;
    border: 1px solid rgba(var(--text-color-rgb), 12%);
    border-radius: 16px;
    padding: 1rem;
    background-color: var(--background-color);
    position: relative;
    transition: all .2s ease;
}

.content {
    display: flex;
    flex-direction: column;
    gap: .2rem;
    width: 100%;
}


.title {
    font-size: .75rem;
    font-weight: 600;
    color: rgba(var(--text-color-rgb), 40%);
    transition: all .2s ease;
}

.title.hover {
    color: var(--primary)
}

.titleWithTag {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.tag {
    text-transform: uppercase;
    font-weight: 800;
    font-size: .75rem;
    color: var(--tertiary);
}
