.container {
    display: flex;
    position: fixed;
    top: 7rem;
    right: 2rem;
    flex-direction: row;
    border: 1px solid rgba(var(--text-color-rgb), 12%);
    padding: 1rem;
    border-radius: 12px;
    background-color: var(--background-color);
    z-index: 2;
    align-items: center;
    gap: 1rem;
    width: 375px;
    background-color: rgb(254, 236, 238);
}

.info{
    width: 100%;
    display: flex;
    flex-direction: column;
}

.title{
    font-size: 0.875rem;
    font-weight: 800;
    color: var(--sf-red);
}

.body {
    font-size: 0.875rem;
    font-weight: 500;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    line-clamp: 5;
    -webkit-box-orient: vertical;
    max-height: 4.5rem;
    overflow: hidden;
    text-overflow: ellipsis;
}

.thumbnail{
    height: 64px;
    width: 64px;
    object-fit: contain;
}


.close {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background-color: transparent;
    border: none;
}

.closeIcon {
    min-height: 20px;
    min-width: 20px;
    height: 20px;
    width: 20px;
    opacity: 0.8;
}

@media screen and (max-width: 540px) {
    
    .container {
        width: 90vw;
        top: calc(70px + 1rem);
        right: 0;
        left: 0;
        margin: 0 auto;
    }

}