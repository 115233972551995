.draggable {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
}

.draggableItem {
  display: flex;
  flex-direction: row;
  gap: .5rem;
  flex-wrap: nowrap;
  width: 100%;
}

.drag {
  cursor: grab;
  width: 34px;
  height: 100%;
  background-color: rgba(var(--text-color-rgb), 20%);
  border-radius: 10px;
  border: 1px solid rgba(var(--text-color-rgb), 12%);
  display: flex;
  justify-content: center;
  align-items: center;
}
