.dropzone {
    background-color: rgba(var(--tertiary-rgb), 8%);
    border: 2px dashed #4CB4FF32;
    border-radius: 12px;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    flex: 1;
    height: 100%;
}

.dropzoneActions {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    flex-wrap: wrap;
}

.dropzoneContainer {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    margin: 0;
}

.dropzoneLabel {
    font-family: var(--primary-font);
    font-weight: 500;
    font-size: 0.75rem;
    color: rgba(var(--text-color-rgb), 72%);
    text-align: center;
    width: 100%;
}

.dropzoneProgressLabel {
    display: flex;
    flex-direction: column;
    gap: .5rem;
}

.dropzoneUploadLabel {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.playerWrapper {
    display: flex;
    aspect-ratio: 16/9;
    width: 100%;
    border-radius: 12px;
    position: relative;
}

.player>video {
    border-radius: 12px;
    background-color: black;
}

.thumbnailWrapper {
    display: flex;
    flex-direction: column;
    position: relative;

}

.thumbnail {
    width: 100%;
    object-fit: scale-down;
    border: 1px solid rgba(var(--text-color-rgb), 12%);
    border-radius: 1rem;
    background-color: white;
    border-radius: 8px;
}

.remove {
    position: absolute;
    top: .2rem;
    right: .2rem;
    color: var(--background-color);
    background-color: rgba(var(--text-color-rgb), 50%);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    padding: .2rem;
}