.noWrapper {}

.wrapper {
  display: flex;
  flex-direction: column;
  border-radius: 14px;
  background-color: white;
  overflow: hidden;
  border: 1px solid rgba(var(--text-color-rgb), 12%);
  padding: 1rem;
  gap: 1rem;
}

.locked {
  filter: grayscale(1);
}

.container {
  display: flex;
  flex-direction: row;
  border-radius: 14px;
  background-color: var(--background-color);
  overflow: hidden;
  border: 1px solid rgba(var(--text-color-rgb), 12%);
  padding: 1rem;
  gap: 1rem;
}

.courseName {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  flex-wrap: wrap;
}

.left {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: space-between;
  flex: 1;
}

.right {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.thumbnail {
  display: flex;
  width: 230px;
  height: 130px;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  border-radius: 12px;
  overflow: hidden;
}

.thumbnail>img {
  display: flex;
  object-fit: cover;
  width: 100%;
}


.info {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  gap: .5rem;
}

.description {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  max-height: 3.6em;
  /* (line-height * numero di righe) + eventuali padding/margini */
  overflow: hidden;
  text-overflow: ellipsis;
}

.students {
  display: flex;
  flex-direction: row;
  gap: .5rem;
  align-items: center;
}

.studentsCounter {
  font-family: "Manrope";
  font-weight: 700;
  font-size: 1rem;
}

@media screen and (max-width: 540px) {
  .container {
    margin: 1rem auto;
  }
}
