.container {
    display: flex;
    overflow: hidden;
    position: relative;
    background-color: #171717;
    width: 100%;
    flex-direction: row;
    flex-wrap: wrap;
    gap: .5rem;
}

.container.sideBySide {
    height: 100%;
    border-radius: 1rem;
    aspect-ratio: 16/9;
}

.stream {
    display: flex;
    position: relative;
    flex-basis: 100%;
    height: 100%;
    border-radius: 1rem;
}

.stream.multi {
    flex-basis: calc(50% - 0.25rem);
    overflow: hidden;
}

.stream.multi.half {
    height: calc(50% - 0.25rem);
}

.closeStream {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 1rem;
    right: 1rem;
    gap: .25rem;
    height: 30px;
    width: 30px;
    padding: .5rem;
    color: white;
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 64%);
    cursor: pointer;
    pointer-events: all;
}

.cameraOff {
    display: flex;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 30%;
    height: 30%;
    display: flex;
    object-fit: contain;
}

.cameraOff>path {
    fill: rgba(255, 255, 255, 0.5);
}

.cameraOffSmall {
    display: flex;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 40px;
    height: 40px;
    display: flex;
    object-fit: contain;
}

.cameraOffSmall>path {
    fill: rgba(255, 255, 255, 0.5);
}

.pinBadge {
    display: flex;
    height: 24px;
    width: 24px;
    background-color: var(--sf-yellow);
    border-radius: 12px;
    justify-content: center;
    align-items: center;

    position: absolute;
    top: .5rem;
    left: .5rem;
    color: white;
}

.pinBadge>svg {
    width: 12px;
    height: 12px;
    min-width: 12px;
    min-height: 12px;
}

.micBadge {
    display: flex;
    height: 24px;
    width: 24px;
    background-color: rgba(var(--background-color-rgb), 10%);
    border-radius: 12px;
    justify-content: center;
    align-items: center;

    position: absolute;
    bottom: .5rem;
    right: .5rem;
}

.micBadge>svg {
    height: 16px;
    width: 16px;
    min-width: 16px;
    min-height: 16px;
}

.micBadge.disabled {
    background-color: #F43A3A;
}

.micBadge.disabled>svg>path {
    fill: var(--background-color);
}

.hostScreenshareName {
    position: absolute;
    font-family: var(--primary-font);
    font-weight: 600;
    font-size: 0.75rem;
    color: var(--background-color);
    padding: .5rem 1rem;
    bottom: .5rem;
    right: .5rem;
    background-color: rgba(0, 0, 0, 64%);
    border-radius: 8px;
}

.participantName {
    position: absolute;
    font-family: var(--primary-font);
    font-weight: 600;
    font-size: 0.75rem;
    color: var(--background-color);
    padding: .5rem 1rem;
    bottom: .5rem;
    left: .5rem;
    background-color: rgba(0, 0, 0, 64%);
    border-radius: 8px;
}

.participantName.screenshare {
    left: 1.5rem;
}

.name {
    font-family: var(--primary-font);
    font-weight: 600;
    font-size: 0.75rem;
    color: var(--background-color);
    padding: .5rem 1rem;
    bottom: .15rem;
    border-radius: 8px;
}

.selfViews {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    position: absolute;
    bottom: 1rem;
    left: -100%;
    transition: all .2s ease-in-out;
    z-index: 1;
    max-width: 220px;
}

.selfViews.open {
    bottom: 1rem;
    left: 1.5rem;
}

.selfViews.expanded {
    height: 100%;
    width: 100%;
    border-radius: 1rem;
    transition: all .2s ease-in-out;
    left: 0;
    bottom: 0;
    opacity: 1;
    pointer-events: none;
    max-width: unset;
}

.selfViewWrapper {
    display: flex;
    flex-direction: column;
    background-color: rgba(0, 0, 0, 1);
    border-radius: 1rem;
    overflow: hidden;
    max-height: 140px;
    width: 220px;
    border: 1px solid rgba(255, 255, 255, 0.2);
    flex-basis: 25%;
    position: relative;
}

.selfViewWrapper.expanded {
    height: 100%;
    width: 100%;
    flex-basis: 100%;
    border-radius: 1rem;
    transition: all .2s ease-in-out;
    left: 0;
    bottom: 0;
    opacity: 1;
    pointer-events: none;
    max-width: unset;
    max-height: unset;
}

.selfViewWrapper.hidden {
    display: none;
}

/* .selfViewWrapper.expanded > .selfView {
    height: 100%;
} */

.selfViewWrapper.expanded>.name {
    position: absolute;
    bottom: 1rem;
    left: 1rem;
    background-color: rgba(0, 0, 0, 0.8);
}

.selfViewWrapper>.name {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-right: .5rem;
    gap: 1rem;
}

.selfViewWrapper>.name>.micBadge {
    position: relative;
    left: unset;
    bottom: unset;
    right: unset;
    top: unset;
}

.selfView {
    height: 100%;
    overflow: hidden;
    transition: all .2s ease-in-out;
    cursor: pointer;
    position: relative;
}

.closeSelfView {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 1rem;
    right: 1rem;
    gap: .25rem;
    /* height: 30px;
    width: 30px; */
    padding: .5rem;
    color: white;
    border-radius: 12px;
    background-color: rgba(0, 0, 0, 64%);
    cursor: pointer;
    pointer-events: all;
}

.userTip {
    position: absolute;
    height: 100px;
    width: 20px;
    background-color: rgb(0, 0, 0);
    bottom: 1rem;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0 .25rem .25rem 0;
    cursor: pointer;
    opacity: 0.4;
    transition: opacity .2s ease-in-out;
    z-index: 2;
}

.userTip:hover {
    opacity: 1;
    transition: opacity .2s ease-in-out;
}

.chevron {
    transform: rotate(90deg);
    color: white;
}

.chevron.open {
    transform: rotate(-90deg);
}