.container {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 100vw;
    height: 100%;
    min-height: 100vh;
    margin: 0 auto;
    overflow: hidden;
    background-color: var(--background-secondary-color);
}

.section {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: fit-content;
    align-items: center;
    padding: 2rem 0;
}

.sectionInner {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 1440px;
    padding: 0 3rem;
    gap: 1rem;
}

.divider {
    display: flex;
    width: 100%;
    height: 2px;
    background-color: rgba(var(--text-color-rgb), 12%);
}

.subtitle {
    font-weight: 500;
}

.header {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
}

.arrows {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    width: fit-content;
    gap: .5rem;
}

.arrowButton {
    aspect-ratio: 1;
    background-color: transparent;
    border: 1px solid rgba(var(--text-color-rgb), 12%);
    border-radius: 20px;
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.arrowButton:disabled>svg>path {
    stroke: rgba(var(--text-color-rgb), 12%);
}

.arrowButton>svg>path {
    stroke: var(--text-color);
}

.arrowButton.left {
    transform: rotate(-90deg);
}

.arrowButton.left>svg {
    transform: translateY(-.5px);
    width: 12px;
    height: 12px;
    min-width: 12px;
    min-height: 12px;
}

.arrowButton.right {
    transform: rotate(90deg);
}

.arrowButton.right>svg {
    transform: translateY(-.5px);
    width: 12px;
    height: 12px;
    min-width: 12px;
    min-height: 12px;
}

.lessons {
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: center;
    height: fit-content;
}

.lessonCard.slideLeft {
    animation: slide-left 1s ease-in-out;
    animation-fill-mode: forwards;
    animation-delay: .2s;
}

.lessonCard.slideRight {
    animation: slide-right 1s ease-in-out;
    animation-fill-mode: forwards;
    animation-delay: .2s;
}

.lessonCard {
    width: 25%;
    padding: .5rem;
}

.noLessons,
.noCourses {
    display: flex;
    width: 100%;
    flex-direction: column;
    font-size: 1rem;
    color: rgba(var(--text-color-rgb), 64%);
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 3rem 0;
    gap: 1rem;
}

.noCourses>span {
    max-width: 512px;
}

.loaderContainer {
    display: flex;
    height: 200px;
    width: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    position: relative;
}

.cardTitleContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.cardTitle {
    font-size: 1.125rem;
    font-weight: 800;
}

.seeAll {
    font-weight: 800;
    color: var(--primary);
    text-decoration: underline;
    cursor: pointer;
}

.exercise {
    display: flex;
    flex-direction: column;
    gap: 0;
    border: 1px solid rgba(var(--text-color-rgb), 12%);
    border-radius: 14px;
}

.exerciseHeader {
    border-bottom: 1px solid rgba(var(--text-color-rgb), 12%);
    padding: 0.5rem 1rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.exerciseName {
    display: flex;
    flex-direction: row;
    gap: .2rem;
    font-weight: 700;
    font-size: 0.875rem;
}

.exerciseIcon {
    width: 20px;
    height: 20px;
    min-width: 20px;
    min-height: 20px;
    border-radius: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    color: var(--sf-yellow);
    background-color: #FFF8DF;
}

.exerciseIcon>svg {
    width: 12.5px;
    height: 12.5px;
}

.icon {
    width: 16px;
    height: 16px;
}

.exerciseExpiresAt {
    color: rgba(var(--text-color-rgb), 56%);
    font-weight: 700;
    font-size: 0.875rem;
    display: flex;
    align-items: center;
    gap: .2rem;
}

.exerciseExpiresAt.warning {
    color: var(--secondary);
}

.exerciseRecap {
    padding: 0.5rem 1rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 80%
}

.labeled {
    display: flex;
    flex-direction: column;
    gap: .2rem;
    justify-content: center;
}

.label {
    font-weight: 500;
    font-size: 0.875rem;
    color: rgba(var(--text-color-rgb), 40%);
}

.value {
    font-weight: 600;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: nowrap;
    gap: .2rem;
}

.cardContent {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.tests {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: stretch;
    row-gap: 1rem;
}

.test {
    width: 33%;
    padding: 0 .5rem;
    min-width: 320px;
    flex-grow: 1;
}

.asideContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: stretch;
    gap: 1rem;
}

.aside {
    min-width: 400px;
    flex-grow: 1;
    width: calc(50% - 1rem);
}

.noData {
    width: 100%;
    min-height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 700;
    font-size: 1.125rem;
}

.activity {
    border-radius: 14px;
    border: 1px solid rgba(var(--text-color-rgb), 12%);
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    height: 100%;
    margin: 0 1rem;
}

.activityHeader {
    background-color: #FEFAED;
    padding: 0.5rem 1rem;
    display: flex;
    flex-direction: row;
    gap: .5rem;
    align-items: center;
    width: 100%;
    border-bottom: 1px solid rgba(var(--text-color-rgb), 12%);
}

.backoffice {
    --color: #FA9266;
    background-color: var(--background-color);
    width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid var(--color);
    border-radius: 24px;
    color: var(--color);
}

.activityTitle {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    font-weight: 700;
    font-size: 0.875rem;
}

.clock {
    color: rgba(var(--text-color-rgb), 32%);
    display: flex;
    flex-direction: row;
    gap: .3rem;
    align-items: center;
}

.clock>svg {
    width: 16px;
    height: 16px;
}

.activityContent {
    padding: 1rem;
    flex-grow: 1;
    font-weight: 500;
    background-color: var(--background-color);
    min-height: 200px;
}

@media screen and (max-width: 1440px) {

    .lessonCard {
        width: 33%;
    }

}

@media screen and (max-width: 1160px) {

    .lessonCard {
        width: 50%;
    }

}


@media screen and (max-width: 1024px) {

    .lessonCard {
        width: 100%;
    }

}

@media screen and (max-width: 768px) {

    .lessonCard {
        width: 50%;
    }

    .section {
        padding: 2rem 0;
    }

    .sectionInner {
        padding: 0 2rem;
    }

}

@media screen and (max-width: 540px) {

    .lessonCard {
        width: 100%;
    }

    .section {
        padding: 0;
    }

    .sectionInner {
        padding: 1rem;
    }

    .aside {
        min-width: 300px;
    }

}


@keyframes slide-left {
    0% {
        transform: translateX(0);
    }

    100% {
        transform: translateX(-110%);
    }
}

@keyframes slide-right {
    0% {
        transform: translateX(0);
    }

    100% {
        transform: translateX(110%);
    }
}

@media screen and (max-width: 1024px) {

    .sectionInner {
        flex-direction: column;
    }

    .sectionInner>div {
        width: 100%;
    }
}

@media screen and (max-width: 540px) {
    .container {
        padding: 0;
    }

    .section {
        padding: 0;
    }

    .sectionInner {
        padding: 1rem;
        flex-direction: column;
    }

    .sectionInner>div {
        width: 100%;
    }
}
