
.container{
    display: flex;
    flex-direction: column;
    width: 100%;
    position: relative;
}

.content{
    display: flex;
    flex: 1;
    width: 100%;
    position: relative;
}