
.circle {
    background-color: #d1d1d1;
    height: 46px;
    width: 46px;
    animation: glow 2s infinite ease-in-out;
}

.rect {
    background-color: #d1d1d1;
    animation: glow 2s infinite ease-in-out;
}


@keyframes glow {
    0% {
        background-color: #d1d1d1;
    }

    50% {
        background-color: #ededed;
    }

    100% {
        background-color: #d1d1d1;
    }
}