.container {
    display: flex;
    position: fixed;
    top: 7rem;
    right: 2rem;
    flex-direction: row;
    border: 1px solid rgba(var(--text-color-rgb), 12%);
    padding: 1rem;
    border-radius: 12px;
    background-color: var(--background-color);
    z-index: 4;
    align-items: center;
    gap: 1rem;
    width: 375px;
}

.info{
    width: 100%;
    display: flex;
    flex-direction: column;
}

.title{
    font-size: 0.875rem;
    font-weight: 600;
}

.body {
    font-size: 0.875rem;
    font-weight: 800;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    max-height: 3.6em; /* (line-height * numero di righe) + eventuali padding/margini */
    overflow: hidden;
    text-overflow: ellipsis;
}

.thumbnail{
    height: 42px;
    width: 62px;
    object-fit: cover;
    border-radius: 4px;
}


.close {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background-color: transparent;
    border: none;
}

.closeIcon {
    min-height: 20px;
    min-width: 20px;
    height: 20px;
    width: 20px;
    opacity: 0.8;
}

@media screen and (max-width: 540px) {
    
    .container {
        width: 90vw;
        top: calc(70px + 1rem);
        right: 0;
        left: 0;
        margin: 0 auto;
    }

    .container.noTop {
        top: 1rem;
    }

}