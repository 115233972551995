.container {
    display: flex;
    flex-direction: row;
    width: 100%;
    --accentColor: var(--primary);
}

.tab {
    position: relative;
    display: flex;
    flex-direction: row;
    flex: 1;
    padding: .5rem;
    gap: .5rem;
    cursor: pointer;
    color: var(--background-color);
    transition: all .2s ease-in-out;
    border-radius: 10px 10px 0 0;
    justify-content: center;
    align-items: center;
}

.tab.active {
    background-color: var(--accentColor);
}

.name {
    font-size: 0.875rem;
    font-weight: 700;
}

.badge {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(10%, -80%);
    background-color: var(--secondary);
    color: white;
    font-weight: 700;
    font-size: 0.75rem;
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    border: 2px solid white;
    pointer-events: none;
    cursor: pointer;
}