
.container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    position: relative;
}

.videoContainer {
    display: flex;
    flex: 1;
}

.controls {
    display: flex;
    flex-direction: row;
    gap: .5rem;
}
.devices {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.device {
    display: flex;
    width: 100%;
    white-space: nowrap;
    -webkit-line-clamp: 1;
    line-clamp: 1;
    -webkit-box-orient: vertical;
    max-height: 2rem;
    overflow: hidden;
    text-overflow: ellipsis;
    gap: .5rem;
    cursor: pointer;
}

.deviceSelected {
    display: flex;
    min-width: 20px;
    justify-content: center;
    align-items: center;
}

.toolIcon {
    width: 20px;
    height: 20px;
    min-width: 20px;
    min-height: 20px;
}

.toolIcon.active > path {
    fill: var(--primary)
}

.option {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    cursor: pointer;
    align-items: center;
    white-space: nowrap;
}


.results {
    display: flex;
    flex-direction: row;
    gap: 1rem;
}

.result {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: .25rem;
}

.resultTitle{
    font-size: .75rem;
    text-transform: uppercase;
    white-space: nowrap;
}

.resultValue{
    font-size: .75rem;
    font-weight: 700;
    white-space: nowrap;
}

.overlay {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 1rem;
    background-color: rgba(0,0,0,0.8);
    color: var(--background-color);
    max-width: 420px;
    border-radius: 1rem;
}

.overlay.sideBySide {
    position: relative;
    transform: translate(0, 0);
    top: unset;
    left: unset;
}

.overlay.sideBySide .results{
    flex-wrap: wrap;
    row-gap: 1rem;
    column-gap: 0;
    padding: .5rem 0;
}
.overlay.sideBySide .result{
    flex-basis: 33%;
}
.overlay.sideBySide .actions{
    flex-direction: column-reverse;
    gap: 1rem;
}

.actions {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    align-items: center;
}