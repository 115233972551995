.container {
    display: flex;
    position: relative;
    width: 100%;
}

.container:hover>.arrow {
    opacity: 1;
    transition: opacity .1s ease-in;
}

.container:hover>.toolbar {
    opacity: 1;
    transition: opacity .1s ease-in;
}

.container:hover .tool > svg > path {
    opacity: 1;
}

.container:hover>.pageNumber {
    opacity: 1;
    transition: opacity .1s ease-in;
}

.pdfCanvas {
    position: relative;
    width: 100%;
    height: 100%;
}

.arrow {
    background-color: rgba(var(--text-color-rgb), 12%);
    border-radius: 30px;
    height: 40px;
    width: 40px;
    color: white;
    border: none;
    cursor: pointer;
    opacity: 0;
    transition: opacity .1s ease-in;
}

.arrow:hover {
    background-color: rgba(var(--text-color-rgb), 82%);
}

.arrow.left {
    position: absolute;
    top: 50%;
    left: 1rem;
    transform: rotate(-90deg);
}

.arrow.right {
    position: absolute;
    top: 50%;
    right: 1rem;
    transform: rotate(90deg);
}

.toolbar {
    display: flex;
    flex-direction: row;
    position: absolute;
    bottom: .5rem;
    left: 50%;
    transform: translateX(-50%);
    background-color: rgba(var(--text-color-rgb), 12%);
    border-radius: 12px;
    justify-content: center;
    align-items: center;
    padding: .5rem;
    gap: .5rem;
    opacity: 0;
    transition: opacity .1s ease-in;
}

.toolbar:hover {
    background-color: rgba(var(--text-color-rgb), 82%);
}

.tool {
    cursor: pointer;
    padding: .5rem;
    color: white;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: all .1s ease-in-out;
}

.tool > svg > path {
    opacity: 0;
}

.tool:hover {
    background-color: rgba(var(--text-color-rgb), 82%);
    transition: all .1s ease-in-out;
}

.tool.active {
    color: rgba(var(--primary-rgb), 80%);
    transition: all .1s ease-in-out;
}

.tool.active:hover {
    color: rgba(var(--primary-rgb), 100%);
    transition: all .1s ease-in-out;
}


.pageNumber {
    display: flex;
    flex-direction: row;
    position: absolute;
    bottom: .5rem;
    right: .5rem;
    border-radius: 12px;
    justify-content: center;
    align-items: center;
    padding: .5rem;
    opacity: 0;
    transition: opacity .1s ease-in;
    color: black;
    font-weight: 600;
    user-select: none;
}
