.container {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    gap: 1rem;
    background-color: var(--background-color);
    border-radius: 12px;
    border: 1px solid rgba(var(--text-color-rgb), 12%);
    padding: 1rem;
}

.left {
    display: flex;
    flex-direction: column;
    flex: 1 1;
    gap: 1rem;
}

.right {
    display: flex;
    flex: 1 1;
    justify-content: flex-end;
}

.thumbnail {
    min-width: 218px;
    max-height: 130px;
    object-fit: cover;
    border-radius: 12px;
}

.name {
    font-size: 1.25rem;
    font-weight: 800;
    text-transform: uppercase;
    display: flex;
    flex-direction: row;
    gap: .5rem;
}

.time {
    display: flex;
    align-items: center;
    gap: .2rem;
    font-size: 1rem;
    font-weight: 700;
}

.tags {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: .5rem;
}

.description p {
    margin: 0;
}

.actions {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    align-items: center;
}

.arrowIcon {
    transform: rotate(180deg);
}

@media screen and (max-width: 540px) {

    .container {
        flex-direction: column-reverse;
    }

    .thumbnail {
        width: 100%;
    }

    .right {
        justify-content: center;
    }

    .actions {
        flex-wrap: wrap;
    }

    .name {
        flex-wrap: wrap;
    }
}
