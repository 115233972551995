.container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100dvh;
    min-height: 100dvh;
    margin: 0 auto;
    background-color: #202020;
}

.ended {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    color: rgba(255,255,255, 0.88);
    padding: 2rem;
    text-align: center;
}