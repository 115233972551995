@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap');
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@500;700&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Work+Sans:ital,wght@0,100..900;1,100..900&display=swap');

@font-face {
    font-family: 'SF-Pro';
    src: url('./assets/fonts/SF-Pro.ttf') format('truetype');
}

@font-face {
    font-family: 'Avenir Next';
    src: url('./assets/fonts/AvenirNext-Heavy.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}

.starting-finance {

    --primary-rgb: 76, 180, 255;
    --primary: #4CB4FF;

    --secondary-rgb: 109, 74, 255;
    --secondary: #6d4aff;

    /* --secondary-rgb: 250, 102, 117;
    --secondary: #fa6675; */

    --tertiary-rgb: 76, 180, 255;
    --tertiary: #4CB4FF;
    /* --tertiary-rgb: 109, 74, 255;
    --tertiary: #6d4aff; */

    --sf-yellow-rgb: 247, 164, 0;
    --sf-yellow: #F7A400;

    --sf-green-rgb: 46, 176, 90;
    --sf-green: #2EB05A;

    --sf-orange-rgb: 255, 134, 82;
    --sf-orange: #FF8652;

    --text-color-rgb: 38, 38, 38;
    --text-color: #262626;

    --sf-light-green-rgb: 47, 198, 160;
    --sf-light-green: #2fc6a0;

    --sf-red-rgb: 250, 102, 117;
    --sf-red: #fa6675;

    --sf-purple-rgb: 143, 91, 255;
    --sf-purple: #8f5bff;

    --sf-fucsia-rgb: 212, 91, 255;
    --sf-fucsia: #D45BFF;

    --sf-pink-rgb: 255, 91, 248;
    --sf-pink: #FF5BF8;

    --sf-blue-rgb: 19, 85, 255;
    --sf-blue: #1355ff;

    --sf-aqua-rgb: 39, 229, 217;
    --sf-aqua: #27E5D9;

    --sf-gray-rgb: 181, 184, 190;
    --sf-gray: #B5B8BE;

    --background-color-rgb: 252, 252, 252;
    --background-color: #fcfcfc;

    --background-secondary-color-rgb: 244, 244, 244;
    --background-secondary-color: #f4f4f4;

    --background-fourth-color-rgb: 240, 244, 247;
    --background-fourth-color: #f0f4f7;

    --primary-font: 'Manrope';
    --secondary-font: 'Work Sans';

    --sidenav-color: #fcfcfc;

}


/* @media screen and (max-width: 768px) {
    .starting-finance {
        --background-secondary-color-rgb: 252, 252, 252;
        --background-secondary-color: #fcfcfc;

        --background-color-rgb: 244, 244, 244;
        --background-color: #f4f4f4;

        --sidenav-color: #fcfcfc;
    }

} */
