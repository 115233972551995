.container {
    display: flex;
    flex-direction: row;
    width: 100%;
    position: relative;
    border: 1px solid rgba(var(--text-color-rgb), 12%);
    border-radius: 1rem;
    padding: .9rem;
    gap: 4px;
    flex-wrap: wrap;
    background-color: white;
}

.container.open {
    border-radius: 1rem 1rem 0rem 0rem;
}

.roundedInput {
    display: flex;
    box-sizing: border-box;
    font-family: 'Manrope';
    font-weight: 700;
    letter-spacing: 0.5%;
    background-color: transparent;
    border: none;
    padding: .2rem;
    flex: 1;
}

.roundedInput::placeholder {
    font-family: var(--primary-font);
    font-weight: 600;
    opacity: 40%;
}

.roundedInput:focus {
    outline: none;
}

.roundedInput:hover {
    outline: none;
}

.roundedInput:disabled:hover {
    outline: none;
    border: 1px solid rgba(var(--text-color-rgb), 12%);
    cursor: not-allowed;
}

.options {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    position: absolute;
    top: 25px;
    left: -1px;
    right: -1px;
    max-height: 150px;
    z-index: 99;
    background-color: white;
    border: 1px solid rgba(var(--text-color-rgb), 12%);
    border-radius: 0 0 1rem 1rem;
    font-family: 'Manrope';
    font-weight: 700;
    letter-spacing: 0.5%;
    overflow: scroll;
    padding-top: 20px;
    padding: 1rem;
    gap: 4px;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.chevron {
    position: absolute;
    top: 20px;
    right: 1rem;
    height: 16px;
    width: 16px;
    transform: rotateX(180deg);
    cursor: pointer;
    transition: .2s all ease-in-out;
}

.chevron.open {
    transform: rotateX(0);
    transition: .2s all ease-in-out;
}
