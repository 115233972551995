.settings {
    display: flex;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    height: 100%;
    background-color: rgba(var(--text-color-rgb), 88%);
    overflow: hidden;
    padding: 1rem 0;
    flex-direction: column;
    gap: 0.5rem;
    border-radius: 12px 0 0 12px;
    z-index: 10;
    box-sizing: border-box;
    width: 0;
    transition: all .1s ease-in-out;
}

.settings.open {
    width: max(33%, 400px);
    padding: 1rem;
    transition: all .1s ease-in-out;
}

.settings.open.sideBySide {
    background-color: transparent;
    width: 100%;
    padding: 0 !important;
    position: relative;
}

.settingsHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: var(--background-color);
}

.settingsContent {
    display: flex;
    flex-direction: column;
    padding: 1rem 0;
    gap: .5rem;
    height: 100%;
    overflow-y: scroll;
    color: var(--background-color);
}

.settingsContent.sideBySide{
    padding: 0;
}

.settingsContent::-webkit-scrollbar {
    display: none;
}

.settingsRow {
    background-color: rgba(var(--background-color-rgb), 10%);
    border-radius: 8px;
    padding: .5rem;
    font-weight: 600;
    font-size: .75rem;
    display: flex;
    flex-direction: column;
    gap: .25rem;
}

.settingsContent.sideBySide > .settingsRow {
    background-color: rgba(255,255,255,0.05);
}

.settingsRowInner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
}

.settingsTitle {
    font-weight: 700;
    font-size: .875rem;
}

.backgrounds {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
    flex-wrap: wrap;
    width: 100%;
}

.background {
    object-fit: contain;
    aspect-ratio: 16 / 9;
    width: 48%;
    cursor: pointer;
    border-radius: 4px;
}

.background.selected {
    border: 2px solid var(--primary);
}

.spacer {
    display: flex;
    flex: 1;
}