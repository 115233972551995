.cardHeader {
  display: flex;
  flex-direction: row;
  width: 100%;
  border-bottom: 1px solid rgba(var(--text-color-rgb), 12%);
  padding: 1rem 1.5rem;
  align-items: center;
  gap: .7rem;
}

.icon {
  width: 32px;
  height: 32px;
  min-width: 32px;
  min-height: 32px;
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon.material {
  background-color: #DFFFEA;
  color: var(--sf-green);
}

.info {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.title {
  font-size: 1rem;
  font-weight: 700;
  color: var(--text-color)
}

.subtitle {
  font-size: 0.875rem;
  font-weight: 500;
  color: rgba(var(--text-color-rgb), 72%);
  display: flex;
  align-items: center;
  gap: .2rem;
}

.clockIcon {
  height: 12px;
  width: 12px;
}

.clockIcon>g>path {
  stroke: rgba(var(--text-color-rgb), 72%)
}

.date {
  font-size: 0.875rem;
  font-weight: 700;
  color: rgba(var(--text-color-rgb), 32%);
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  white-space: nowrap;
  gap: 0.4rem;
}

.date>.clockIcon {
  width: 20px;
  height: 20px;
}

.date>.clockIcon>g>path {
  stroke: rgba(var(--text-color-rgb), 32%);
}

.content {
  padding: 1.5rem;
  padding-top: .5rem;
  display: flex;
  flex-direction: column;
  gap: .5rem;
}

.message {
  padding-bottom: 1rem;
  font-weight: 500;
  font-size: 0.875rem;
}
