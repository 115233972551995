.container {
    position: relative;
    height: 300px;
    width: 100%;
    border-radius: 12px;
    overflow: hidden;
    margin: 1rem 0;
    background-color: rgba(var(--text-color-rgb), 12%);
}

.saveButton {
    background-color: var(--tertiary);
}

@media (hover: hover) {

    
    .saveButton:hover{
        color: var(--tertiary);
        background-color: var(--background-color);
        box-shadow:inset 0px 0px 0px 2px var(--tertiary);
        transition: .2s ease-in-out all;
    }

    .saveButton:hover > svg {
        stroke: var(--tertiary);
    }

}
