.container {
  width: 32px;
  padding-top: 6px;
  position: relative;
}

.actions {
  position: absolute;
  top: 32px;
  right: 0;
  transition: .2s ease-in-out all;
  width: calc(100% - (var(--container-offset) * 2) + 2px);
  z-index: 2;
  background-color: var(--background-color);
  border-radius: 6px;
  border: 1px solid rgba(var(--text-color-rgb), 12%);
  overflow: hidden;
  cursor: pointer;
}

.actions.open {
  opacity: 1;
}

.actions.closed {
  opacity: 0;
  z-index: -100;
}

.icon {
  width: 12px;
  height: 12px;
  min-width: 12px;
  min-height: 12px;
}

.action {
  display: flex;
  flex-direction: row;
  gap: .5rem;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-start;
  font-size: .875rem;
  font-weight: 700;
  padding: 0.5rem 1rem;
  border-bottom: 1px solid rgba(var(--text-color-rgb), 12%);
}

.label {
  color: var(--text-color) !important;
  white-space: nowrap;
}

.action:last-child {
  border-bottom: none;
}

@media(hover: hover) {
  .action:hover {
    background-color: var(--background-secondary-color);
  }
}
