.loader {
  width: 60px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}

.ball {
  list-style: none;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: #B4E8DA;
  margin: 0;
  margin-top: .5rem;
}

.ball:nth-child(1) {
  animation: bounce-1 1s ease-in-out infinite;
}

@keyframes bounce-1 {
  50% {
    transform: translateY(-10px);
  }
}

.ball:nth-child(2) {
  animation: bounce-3 1s ease-in-out 0.1s infinite;
}

@keyframes bounce-2 {
  50% {
    transform: translateY(-10px);
  }
}

.ball:nth-child(3) {
  animation: bounce-3 1s ease-in-out 0.3s infinite;
}

@keyframes bounce-3 {
  50% {
    transform: translateY(-10px);
  }
}