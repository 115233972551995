.container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
}

.cardContent,
.innerCard {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: .75rem;
  flex: 1 1;
  min-height: auto;
  overflow: visible;
}

.innerCard {
  gap: .2rem;
}


.sectionTitle {
  font-size: .75rem;
  font-weight: 600;
  color: rgba(var(--text-color-rgb), 40%);
}

.textArea {
  border: none;
  padding: 0;
  width: 100%;
  background-color: transparent;
  resize: none;
  font-family: "Manrope";
  font-size: 1rem;
  font-weight: 700;
  flex: 1 1;
  color: var(--text-color);
  min-height: 60px;
}

.textArea:focus {
  outline: none;
}

.textArea::placeholder {
  color: rgba(var(--text-color-rgb), 12%)
}

.words {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  column-gap: .4rem;
  row-gap: 6px;
  user-select: none;
  padding-bottom: 1rem;
  min-height: 60px;
}


.word {
  font-weight: 700;
  transition: all .2s ease;
  height: 24px;
  display: flex;
  align-items: center;
}

.word:not(.hoverWord, .selectedWord) {
  text-decoration: underline;
  text-decoration-style: dashed;
  text-decoration-color: rgba(var(--text-color-rgb), 20%);
  text-underline-offset: 4px;
}

.icon {
  width: 12px;
  height: 12px;
}

.hoverWord {
  padding: 1px 6px;
  border-radius: 8px;
  border: 1px rgba(var(--sf-light-green-rgb), 40%);
  background-color: rgba(var(--sf-light-green-rgb), 20%);
  color: var(--sf-light-green);
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: .4rem;
  cursor: pointer;
}

.hoverWord>.icon {
  color: var(--sf-light-green)
}

.selectedWord {
  padding: 1px 6px;
  border-radius: 8px;
  border: 2px solid rgba(var(--tertiary-rgb), 60%);
  color: var(--tertiary);
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: .4rem;
  cursor: pointer;
}

.selectedWord>.icon {
  color: rgba(var(--tertiary-rgb), 60%);
}

.cardContent {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: .2rem;
  flex: 1 1
}

.sectionTitle {
  font-size: .75rem;
  font-weight: 600;
  color: rgba(var(--text-color-rgb), 40%)
}

.editor {
  font-family: var(--primary-font);
  background-color: var(--background-color);
  z-index: 0;
}
