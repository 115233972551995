
.container{
    min-height: 80px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 3rem;
    border-bottom: 1px solid rgba(var(--text-color-rgb), 12%);
    justify-content: space-between;
    position: sticky;
    top: 0;
    z-index: 11;
    background-color: var(--background-color);
    gap: 1rem;
}

.search{
  max-width: 310px;
} 

.dropdowns {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;
}

@media screen and (max-width: 768px) {
    .container{
        display: none;
    }
}

/* @media screen and (max-width: 540px) {
    .container{
        padding: 1rem;
        border-bottom: 0px solid;
    }

    .search{
        max-width: 100%;
        width: 100%;
    }
} */