.container{
    display: flex;
    position: relative;
}

.iconButton {
    height: 40px;
    border: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: .2rem;
    position: relative;
    padding: .5rem;
    color: var(--background-color);
    background-color: rgba(var(--background-color-rgb), 10%);
    border-radius: 12px;
    position: relative;
    overflow: hidden;
}

.iconButton:hover{
    background-color: rgba(var(--background-color-rgb), 20%);
}

.iconButton.hasOptions {
    padding-right: .25rem;
    border-radius: 12px 0 0 12px;
}

.optionsButton {
    background-color: rgba(var(--background-color-rgb), 10%);
    border: none;
    padding: .5rem;    
    height: 40px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0 12px 12px 0;
    position: relative;
    overflow: hidden;
}

.optionsButton:hover{
    background-color: rgba(var(--background-color-rgb), 20%);
}


.options {
    background-color: #2e2e2e;
    position: absolute;
    bottom: 50px;
    transform: translateX(-50%);
    z-index: 2;
    border-radius: 12px;
    display: flex;
    color: var(--background-color);
    font-size: 0.875rem;
    font-weight: 600;
    padding: 1rem;
    gap: 1rem;
    flex-direction: column;
    max-width: 90vw;
}

.tooltip {
    background-color: #2e2e2e;
    position: absolute;
    bottom: 50px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 5;
    border-radius: 12px;
    display: none;
    color: var(--background-color);
    font-size: 0.875rem;
    font-weight: 600;
    padding: 1rem;
    gap: 1rem;
    flex-direction: column;
    max-width: 90vw;
    white-space: nowrap;
}

.chevron {
    width: 12px;
    height: 100%;
    min-width: 12px;
    pointer-events: none;
}

.chevron > path {
    stroke: white;
}

.chevron.close {
    transform: rotate(180deg);
}

.badge {
    position: absolute;
    top: 0px;
    right: 0px;
    background-color: var(--secondary);
    color: white;
    font-weight: 700;
    font-size: 0.75rem;
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    border: 2px solid white;
    pointer-events: none;
    cursor: pointer;
}

.audioLevel {
    display: flex;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 0%;
    background-color: rgba(var(--primary-rgb), 25%);
    transition: all .1s ease-in-out;
    pointer-events: none;
}


@media(hover:hover){
    .container:hover > .tooltip {
        display: flex;
    }
}