.container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    min-height: 100vh;
    margin: 0 auto;
    background-color: var(--background-secondary-color);
}

.content {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 1rem;
    width: 100%;
}

.cardContent {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: .2rem;
    flex: 1 1
}

.sectionTitle {
    font-size: .75rem;
    font-weight: 600;
    color: rgba(var(--text-color-rgb), 40%)
}

.tags {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    gap: .5rem;
    flex-wrap: wrap;
}

.dateContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.date {
    font-weight: 700;
    font-size: 1rem;
    color: rgba(var(--text-color-rgb), 72%);
    text-decoration: underline 0.1px solid;
    display: flex;
    align-items: center;
    gap: .5rem;
}

.thumbnailSection {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    gap: 1rem;
}

.thumbnail {
    max-width: 320px;
    border-radius: 12px;
}

.thumbnailSettings {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    gap: .5rem;
    width: 100%;
}

.thumbnailInput {
    display: flex;
    flex-direction: column;
    gap: .5rem;
}

.helperText {
    font-weight: 600;
    font-size: 0.875rem;
    color: rgba(var(--text-color-rgb), 66%);
}

.size {
    font-weight: 800;
    font-size: 1.25rem;
}

.scope {
    font-weight: 500;
    font-size: .75rem;
    padding-top: .2rem;
}

.upload {
    justify-self: flex-end;
}

.save {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}


.blueButton {
    background-color: var(--tertiary);
}

.dialog {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 1rem;
}

.dialogAction {
    justify-self: flex-end;
    background-color: var(--background-secondary-color);
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
}

.succeded {
    display: flex;
    justify-content: center;
    align-items: center;
}

.succeded>svg {
    max-height: 96px;
}

.suggestionMessage {
    text-align: center;
    font-weight: 600;
}

.editor {
    font-family: var(--primary-font);
}

@media (max-width: 768px) {

    .thumbnailSection {
        flex-wrap: wrap;
        justify-content: center;
    }

    .thumbnail {
        max-width: 100%;
    }

    .thumbnailSettings {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: stretch;
        gap: .5rem;
    }
}

@media screen and (max-width: 540px) {
    .content {
        padding: 0;
    }
}