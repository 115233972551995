.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  margin: 0 auto;
  background-color: var(--background-secondary-color);
}

.section {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: fit-content;
  align-items: center;
  padding: 2rem 0;
}

.sectionInner {
  width: 100%;
  max-width: 1440px;
  padding: 0 3rem;
  gap: 1.25rem;

  display: flex;
  flex-direction: row;
  /* flex-wrap: wrap; */
  justify-content: flex-start;

}

.profileContainer {
  flex-grow: .3;
  height: 100%;
  width: 340px;
}

.profile {
  border: 1px solid rgba(var(--text-color-rgb), 12%);
  border-radius: 14px;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  align-items: center;
  justify-content: center;
  background-color: var(--background-color);
}

.teacher {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
}

.profilePic {
  width: 100px;
  border-radius: 50px;
}

.username {
  font-weight: 800;
  font-size: 1.125rem;
}

.teacherInfo {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.attribute {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  text-align: start;
  justify-content: space-between;
  align-items: center;
  gap: .3rem
}

.label {
  font-family: "Poppins";
  font-weight: 500;
  font-size: 0.875rem;
  color: rgba(var(--text-color-rgb), 40%);
  flex-grow: .5;
  display: flex;
  flex-direction: row;
  gap: .3rem;
  align-items: center;
  text-transform: lowercase;
}

.label svg {
  min-width: 16px;
  min-height: 16px;
  max-width: 16px;
  max-height: 16px;
}

.attributeValue,
.attributeValueFullWidth {
  font-weight: 600;
  font-size: .875rem;
  text-align: end;
  flex-grow: .5;
  word-break: break-all;
}

.attributeValueFullWidth {
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  gap: .3rem;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
}

.logout {
  padding: .5rem 2rem;
  border-radius: 32px;
  border: 1px solid rgba(var(--text-color-rgb), 12%);
  color: rgba(var(--text-color-rgb), 80%);
  font-weight: 700;
  font-size: 0.75rem;
  text-transform: uppercase;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: .3rem;
  background-color: transparent;
  min-width: 160px;
  cursor: pointer;
  transition: all .2s ease;
}

.logout>svg {
  color: var(--secondary)
}

.statsContainer {
  flex-grow: .7;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.card {
  border: 1px solid rgba(var(--text-color-rgb), 12%);
  border-radius: 14px;
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: center;
  background-color: var(--background-color);
}

.header {
  display: flex;
  flex-direction: column;
  gap: .2rem
}

.subtitle {
  font-weight: 800;
  font-size: 0.875rem;
  color: rgba(var(--text-color-rgb), 40%);
  text-transform: uppercase;
}

.title {
  font-weight: 800;
  font-size: 1.25rem;
  text-transform: capitalize;
}

.pieChartContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 1rem;
  justify-content: space-between;
}

.chartBadges {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  flex-grow: 0.5;
}

.chartBadge {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
  background-color: var(--background-secondary-color);
  border-radius: 8px;
  padding: 0.7rem 1rem;
  gap: 1rem;
}

.chartBadge>.left {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: .5rem;
  flex-grow: 1;
}

.chartColor {
  width: 12px;
  height: 12px;
  border-radius: 12px;
}

.pieChart {
  flex-grow: .5;
  display: flex;
  align-items: center;
  justify-content: center;
}

.chartLabel {
  font-weight: 600;
  font-size: 0.875rem;
  word-break: keep-all;
  white-space: nowrap;
}

.chartValue {
  font-weight: 800;
  font-size: .875rem;
}

.innerCards {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  flex-wrap: wrap;
}

.outerCards {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 1rem;
}

@media screen and (max-width: 1024px) {
  .pieChartContainer {
    flex-direction: column-reverse;
    flex-wrap: nowrap;
  }
}

@media screen and (max-width: 900px) {
  .sectionInner {
    flex-direction: column;
  }

  .profileContainer {
    width: 100%;
  }
}

@media (hover:hover) {
  .logout:hover {
    border: 1px solid var(--secondary);
  }
}
