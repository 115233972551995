.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 100vw;
  height: 100%;
  min-height: 100vh;
  margin: 0 auto;
  overflow: hidden;
  background-color: var(--background-secondary-color);
}

.section {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: fit-content;
  align-items: center;
  padding: 2rem 0;
}

.sectionInner {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1440px;
  padding: 0 3rem;
  gap: 1rem;
}

.header {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
}

.arrows {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  width: fit-content;
  gap: .5rem;
}

.arrowButton {
  aspect-ratio: 1;
  background-color: transparent;
  border: 1px solid rgba(var(--text-color-rgb), 12%);
  border-radius: 20px;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.arrowButton:disabled > svg > path {
  stroke: rgba(var(--text-color-rgb), 12%);
}

.arrowButton > svg > path {
  stroke: var(--text-color);
}

.arrowButton.left{
  transform: rotate(-90deg);
}

.arrowButton.left > svg {
  transform: translateY(-.5px);
  width: 12px;
  height: 12px;
  min-width: 12px;
  min-height: 12px;
}

.arrowButton.right{
  transform: rotate(90deg);
}

.arrowButton.right > svg {
  transform: translateY(-.5px);
  width: 12px;
  height: 12px;
  min-width: 12px;
  min-height: 12px;
}

.lessons {
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  height: fit-content;
}

.lessonCard.slideLeft {
  animation: slide-left 1s ease-in-out;
  animation-fill-mode: forwards;
  animation-delay: .2s;
}

.lessonCard.slideRight {
  animation: slide-right 1s ease-in-out;
  animation-fill-mode: forwards;
  animation-delay: .2s;
}

.lessonCard {
  width: 25%;
  padding: .5rem;
}

.noLessons, .noCourses {
  display: flex;
  width: 100%;
  flex-direction: column;
  font-size: 1rem;
  color: rgba(var(--text-color-rgb), 64%);
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 3rem 0;
  gap: 1rem;
}

.noCourses > span {
  max-width: 512px;
}

.loaderContainer{
  display: flex;
  height: 200px;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: relative;
}

@media screen and (max-width: 1440px) {
    
  .lessonCard {
      width: 33%;
  }

}

@media screen and (max-width: 1160px) {
  
  .lessonCard {
      width: 50%;
  }

}


@media screen and (max-width: 1024px) {
  
  .lessonCard {
      width: 100%;
  }

}

@media screen and (max-width: 768px) {
  
  .lessonCard {
      width: 50%;
  }

  .section{
      padding: 2rem 0;
  }

  .sectionInner{
      padding: 0 2rem;
  }

}

@media screen and (max-width: 540px) {
  
  .lessonCard {
      width: 100%;
  }

  .section{
      padding: 0;
  }

  .sectionInner{
      padding: 1rem;
  }

}


@keyframes slide-left {
  0% {
      transform: translateX(0);
  }
  100% {
      transform: translateX(-110%);
  }
}

@keyframes slide-right {
  0% {
      transform: translateX(0);
  }
  100% {
      transform: translateX(110%);
  }
}

