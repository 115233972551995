.label {
    display: flex;
    flex-direction: column;
    width: 30px;
    cursor: pointer;
}

.label>span {
    background: var(--primary);
    border-radius: 10px;
    height: 3px;
    margin: 3px 0;
    transition: .2s cubic-bezier(0.68, -0.6, 0.32, 1.6);
}


.label>span:nth-of-type(1) {
    width: 100%;

}

.label>span:nth-of-type(2) {
    width: 100%;
}


.label>span:nth-of-type(3) {
    width: 100%;

}


.label>.input[type="checkbox"] {
    display: none;
}


.label>.input[type="checkbox"]:checked~span:nth-of-type(1) {
    transform-origin: bottom;
    width: 50%;
    transform: rotatez(45deg) translate(4px, 0px)
}


.label>.input[type="checkbox"]:checked~span:nth-of-type(2) {

    transform-origin: top;
    width: 98%;
    transform: rotatez(-45deg)
}


.label>.input[type="checkbox"]:checked~span:nth-of-type(3) {

    transform-origin: bottom;
    width: 50%;
    transform: translate(13px, -5px) rotatez(45deg);

}
