.container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: .5rem;
    flex-wrap: wrap;
}

.devices {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.device {
    display: flex;
    width: 100%;
    white-space: nowrap;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    max-height: 2rem;
    overflow: hidden;
    text-overflow: ellipsis;
    gap: .5rem;
    cursor: pointer;
}

.deviceSelected {
    display: flex;
    min-width: 20px;
    justify-content: center;
    align-items: center;
}

.toolIcon {
    width: 20px;
    height: 20px;
    min-width: 20px;
    min-height: 20px;
}

.toolIcon.active > path {
    fill: var(--primary)
}

.option {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    cursor: pointer;
    align-items: center;
    white-space: nowrap;
}
