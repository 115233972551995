.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  margin: 0 auto;
  background-color: var(--background-secondary-color);
  gap: 1rem;
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.title {
  font-size: 2rem;
  font-weight: 800;
}

.top {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 1rem;
}

.content {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
}

.innerTest {
  display: flex;
  flex-direction: column;
  gap: .5rem;
  width: 100%;
  padding: 1rem;
}

.answerContainer {
  display: flex;
  flex-direction: column;
}

.answerWrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
}

.answer {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  padding: .8rem 1rem;
  font-weight: 600;
  align-items: center;
  width: 100%;
  border: 1px solid rgba(var(--text-color-rgb), 12%);
  border-radius: 14px;
  min-height: 60px;
  flex-grow: 1;
}

.answer.correct {
  background-color: rgba(var(--sf-light-green-rgb), 6%);
}

.answer.wrong {
  background-color: rgba(var(--sf-red-rgb), 6%);
}

.left {
  display: flex;
  flex-direction: row;
  gap: 1rem;
}

.correctLabel {
  color: var(--sf-light-green);
  font-weight: 800;
  display: flex;
  align-items: center;
}

.wrongLabel {
  color: rgba(var(--text-color-rgb), 40%);
  font-weight: 800;
  display: flex;
  align-items: center;
}

.cardTitle {
  font-weight: 800;
  font-size: 1.125rem;
}

.badge {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 10px;
  font-weight: 600;
  color: white;
  padding: .3rem .7rem;
  gap: .5rem
}

.badge.correct {
  background-color: var(--sf-light-green);
}

.badge.wrong {
  background-color: var(--sf-red);
}

.studentCard {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.student {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: center;
  align-items: center;
  padding: 2rem;
}


.picture {
  width: 120px;
  height: 120px;
  border-radius: 120px;
  padding: 4px;
}

.name {
  font-weight: 800;
  font-size: 1.125rem;
}


.recap {
  display: flex;
  flex-direction: column;
  gap: .5rem;
  width: 100%;
}

.chartLabels {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: .5rem;
}

.chartData {
  display: flex;
  flex-direction: column;
  width: 30%;
}

.chartCell {
  background: rgba(var(--text-color-rgb), 4%);
  padding: .5rem 1rem;
  border-radius: 8px;
  overflow: hidden;
  flex-grow: 1;
}

.chartRow {
  display: flex;
  flex-direction: row;
  gap: 1rem;
}

.chartColor {
  height: 14px;
  width: 14px;
  border-radius: 30px;
}

.chartColumn {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.chartDataTitle {
  font-family: var(--primary-font);
  font-weight: 800;
}

.chartDataSubitle {
  font-family: var(--primary-font);
  font-weight: 600;
  font-size: 0.8rem;
}

.circularChartColumn {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.blueButton {
  background-color: var(--tertiary);
  color: white;
}

.badges {
  display: flex;
  flex-direction: row;
  gap: .5rem;
  align-items: center;
}

.textCompletion {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.words {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-items: center;
  row-gap: 1rem;
  column-gap: .3rem;
  border-radius: 12px;
  border: 1px solid rgba(var(--text-color-rgb), 10%);
  background-color: var(--background-color);
  padding: 1.5rem 2rem;
  width: 100%;
  font-weight: 600;
  background-color: var(--background-color);
}

.hiddenWord {
  padding: .5rem 1rem;
  border-radius: 12px;
  border: 1px solid rgba(var(--text-color-rgb), 10%);
  background-color: rgba(var(--text-color-rgb), 2%);
  transition: .2s all ease;
  outline-color: var(--sf-light-green);
  outline-width: 1px;
  font-weight: 700;
  font-size: 1rem;
}

.hiddenWord.wrong {
  border: 1px solid var(--sf-red);
  background-color: rgba(var(--sf-red-rgb), 2%);
}

.hiddenWord.wrong span:first-child {
  color: var(--sf-red);
  text-decoration: line-through;
}

.solutions {
  display: flex;
  flex-direction: column;
  gap: .5rem;
}

.solutionContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: .2rem;
  font-weight: 700;
  font-size: 1.25rem;
  color: rgba(var(--text-color-rgb), 80%);
}

.solutionContainer .solution {
  color: var(--sf-light-green);

}

@media (hover: hover) {


  .blueButton:hover {
    color: var(--tertiary);
    background-color: var(--background-color);
    box-shadow: inset 0px 0px 0px 2px var(--tertiary);
    transition: .2s ease-in-out all;
  }

  .blueButton:hover>svg {
    color: var(--tertiary);
    fill: var(--tertiary);
    stroke-width: 0;
  }

}

@media screen and (max-width: 1024px) {

  .answer {
    display: flex;
    flex-direction: column-reverse;
    align-items: flex-start;
    gap: .5rem
  }
}
