.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  margin: 0 auto;
  background-color: var(--background-secondary-color);
}

.section {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: fit-content;
  align-items: center;
  padding: 2rem 0;
}

.sectionInner {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1440px;
  padding: 0 3rem;
  gap: 1rem;
}

.chevron {
  position: absolute;
  top: 1.5rem;
  right: 2rem;
  cursor: pointer;
  transform: rotate(0);
}

.chevron.collapsed{
  transform: rotate(180deg);
}

.module {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
  transition: all .3s ease;
}

.module.collapsed {
  gap: 0;
  transition: all .3s ease;
}

.module.locked {
  filter: grayscale(1);
}

.moduleName {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  align-items: center;
}

.lessons {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  max-height: 10000px;
  transition: max-height 0.3s ease;
}

.lessons.collapsed {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease;
}

.back {
  font-size: 0.875rem;
  font-weight: 800;
  color: var(--primary);
  cursor: pointer;
  width: fit-content;
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: .4rem;
}

@media screen and (max-width: 540px) {

  .sectionInner {
    padding: 0 1rem;
  }

}