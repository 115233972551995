.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: transparent;
  min-height: 196px;
  gap: 1rem;
}

.title {
  font-size: 1.25rem;
  font-weight: 600;
}

.message {
  font-size: 1rem;
  font-weight: 500;
  color: rgba(42, 42, 42, 0.64);
  font-family: var(--secondary-font);
}

.illustration {
  width: 140px;
}

.illustration>svg,
.illustration>img {
  width: 100%;
  height: 100%;
}

.action {
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 540px) {
  .message {
    display: none;
  }
}
