.container,
.transparent {
    display: flex;
    flex-direction: row;
    padding: 1rem;
    border-radius: 14px;
    border: 1px solid rgba(var(--text-color-rgb), 12%);
    transition: .2s all ease;
    background-color: var(--background-fourth-color);
    color: var(--text-color);
    font-family: "Manrope";
    font-size: 1rem;
    font-weight: 600;
    flex: 1 1;
    cursor: pointer;
    justify-content: space-between;
    position: relative;
    --container-height: 56px;
    --container-offset: 0px;
}

.transparent {
    background-color: transparent
}

.embedded {
    display: flex;
    flex-direction: row;
    color: var(--text-color);
    font-family: "Manrope";
    font-size: 1rem;
    padding: 0.2rem 0;
    font-weight: 600;
    flex: 1 1;
    cursor: pointer;
    justify-content: space-between;
    position: relative;
    --container-height: 38px;
    --container-offset: -16px;
}

.arrow {
    transition: .2s ease-in-out all;
    margin-left: 1rem;
    display: flex;
    align-items: center;
}

.arrow.up {
    transform: rotate(0);
}

.arrow.down {
    transform: rotate(180deg);
}

.options,
.inverseOptions,
.transparentOptions {
    position: absolute;
    top: var(--container-height);
    left: var(--container-offset);
    transition: .2s ease-in-out all;
    width: calc(100% - (var(--container-offset) * 2) + 2px);
    z-index: 1;
    background-color: var(--background-fourth-color);
    border-radius: 14px;
    border: 1px solid rgba(var(--text-color-rgb), 12%);
}

.inverseOptions {
    background-color: var(--background-color);
}

.transparentOptions {
    background-color: transparent;
}

.options.open,
.inverseOptions.open,
.transparentOptions.open {
    opacity: 1;
    visibility: visible;
}

.options.hidden,
.inverseOptions.hidden,
.transparentOptions.hidden {
    opacity: 0;
    visibility: hidden;
}

.divider {
    height: 1px;
    width: 100%;
    border-bottom: 1px solid rgba(var(--text-color-rgb), 12%);
}


.option,
.inverseOption {
    padding: 1rem;
    color: var(--text-color);
    font-family: "Manrope";
    font-size: 1rem;
    font-weight: 600;
}

.option {
    background-color: var(--background-fourth-color);
    display: flex;
    flex-direction: column;
    gap: .5rem;
}

.inverseOption {
    background-color: var(--background-color);
    display: flex;
    flex-direction: column;
}

.selectdeOption {
    display: flex;
    flex-direction: column;
}

.title {
    font-weight: 600;
}

.subtitle {
    font-weight: 400;
    font-size: .8rem;
    opacity: 70%;
} 

.option:first-child,
.inverseOption:first-child {
    border-top-left-radius: 14px;
    border-top-right-radius: 14px;
}

.option:last-child,
.inverseOption:last-child {
    border-bottom-left-radius: 14px;
    border-bottom-right-radius: 14px;
}

.placeholder {
    font-family: var(--primary-font);
    font-weight: 600;
    opacity: 70%;
}

@media(hover: hover) {

    .container:hover {
        border: 1px solid rgba(var(--text-color-rgb), 30%);
    }

    .option:hover {
        background-color: var(--background-color);
    }

    .inverseOption:hover {
        background-color: rgba(var(--primary-rgb), 12%)
    }
}
