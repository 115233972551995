.container{
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 2rem;
    min-height: 92vh;
}

.inner {
    display: flex;
    flex-direction: column;
}

.content {
    width: 100%;
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.notFound {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    z-index: 0;
    opacity: 0.1;
}

.header{
    padding: 3rem;
}

.logo{
    width: 10rem;
    height: auto;
    object-fit: contain;
}

.notFound {
    height: 300px;
}