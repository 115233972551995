.courseType{
    color: white;
    font-weight: 800;
    font-size: 0.75rem;
    padding: .2rem .5rem;
    border-radius: 8px;
    display: flex;
    align-items: center;
    white-space: nowrap;
    max-height: 30px;
    width: fit-content;
}

.professional{
    background-color: rgba(131, 87, 255, 0.8);
    border: 2px solid #AE92FF;
}

.masterclass{
    background-color: rgba(255, 113, 52, 0.8);
    border: 2px solid #FFB494;
}