.container {
    display: flex;
    flex-direction: column;
    padding: 0;
    width: 100%;
    height: 100%;
    flex: 1;
    gap: 1rem;
}

.section {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 0;
}

.sectionInner {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: .5rem;
}

.full {
    width: 100%;
    height: 100%;
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
}

.activeParticipant {
    background-color: rgba(255, 255, 255, 0.05);
    height: 55px;
    width: 100%;
    border-radius: 6px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: .5rem;
    gap: .5rem;
}

.activeParticipantAvatar {
    display: flex;
    height: 38px;
    width: 38px;
    object-fit: cover;
    border-radius: 38px;
}

.activeParticipantInfo{
    display: flex;
    flex-direction: column;
    gap: .25rem;
    width: 100%;
}

.tabContent {
    background-color: rgba(0, 0, 0, 0.3);
    border-radius: 12px;
    overflow: scroll;
    flex: 1;
    flex-basis: 0;
    height: 100%;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.tabSplide {
    max-width: calc(100vw - 2rem);
    height: 100%;
}

.tabTrack {
    overflow: hidden;
    position: relative;
    z-index: 0;
    height: 100%;
    padding-left: 0;
    padding-right: 0;
}

.tabSlide {
    display: flex;
    position: relative;
    height: 100%;
}

.list {
    background-color: transparent;
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
}

.listInner {
    display: flex;
    flex-direction: column;
    border: .5rem solid transparent;
    height: 100%;
    overflow: scroll;
    gap: .5rem;
    -ms-overflow-style: none;
    scrollbar-width: none;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.listInner::-webkit-scrollbar {
    display: none;
}

.listSection {
    display: flex;
    flex-direction: column;
    transition: all .3s ease-in-out;
}

.listHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    background-color: rgba(255, 255, 255, 0.05);
    padding: 1rem;
    border-radius: 6px 6px 0 0;
    gap: .5rem;
}

.listHeader.closed {
    border-radius: 6px;
}

.listHeaderTitle {
    font-size: 0.875rem;
    font-weight: 600;
    gap: .5rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.listHeaderTitle span {
    font-weight: 700;
    font-size: 1rem;
}

.chevron {
    display: flex;
    height: 12px;
    width: 12px;
    min-width: 12px;
    min-height: 12px;
    transition: all .3s ease-in-out;
    transform: rotateX(0deg);
}

.chevron.closed {
    transform: rotateX(180deg);
    transition: all .3s ease-in-out;
}

.listContent {
    display: flex;
    flex-direction: column;
    padding: 0 1rem;
    overflow: scroll;
    background-color: rgba(255, 255, 255, 0.05);
    gap: 1rem;
    border-radius: 0 0 6px 6px;
    max-height: 2000px;
    transition: .3s max-height ease-in-out;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.listContent.closed {
    max-height: 0;
    transition: .3s max-height ease-in-out;
    overflow: hidden;
}

.waitingParticipant,
.absentParticipant,
.presentParticipant {
    border-radius: 8px;
    padding: 0;
    font-weight: 600;
    font-size: .875rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
    padding-bottom: 1rem;
}

.screenSharingRequest {
    border-radius: 8px;
    padding: 0;
    font-weight: 600;
    font-size: .875rem;
    display: flex;
    align-items: center;
    gap: 1rem;
    padding-bottom: 1rem;
}

.absentName,
.presentName {
    display: flex;
    width: 100%;
}

.presentInfo {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: .25rem;
}

.absentAvatar,
.presentAvatar {
    width: 40px;
    height: 40px;
    min-width: 40px;
    min-height: 40px;
    border-radius: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 700;
    object-fit: cover;
}

.presentActions{
    display: flex;
    flex-direction: row;
    gap: 0.1rem;
    background-color: rgba(255, 255, 255, 0.1);
    width: fit-content;
    height: fit-content;
    align-items: center;
    border-radius: 6px;
    overflow: hidden;
}

.presentActionButton {
    display: flex;
    padding: .125rem .5rem;
    cursor: pointer;
}

.pinActive {
    background-color: var(--sf-yellow);
}

.micDisabled, .camDisabled {
    background-color: #F43A3A;
}

.actionIcon {
    height: 16px;
    width: 16px;
    color: white;
}

.actionIcon path {
    fill: white;
}

.chat {
    height: 100%;
    flex: 1;
}

.participants {
    display: flex;
    flex-direction: column;
    flex: 1;
    flex-basis: 0;
    width: 100%;
    height: 100%;
    border: .5rem solid transparent;
    overflow: hidden;
    gap: .5rem;
    color: white
}

.participantsScrollView {
    display: flex;
    flex: 1;
    overflow: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.participantsScrollView::-webkit-scrollbar {
    display: none;
}

.participantsInner {
    display: flex;
    flex-direction: column;
    gap: .5rem;
    width: 100%;
    height: fit-content;
}

.settings {
    display: flex;
    flex-direction: column;
    padding: .5rem;
    width: 100%;
}

.settingsInner {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
    height: 100%;
    flex: 1;
    flex-basis: 0;
}

.pinnedLabel {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: .25rem;
    position: sticky;
    top: 0;
    z-index: 1;
    background-color: #161616;
    padding: .25rem;
}

.divider {
    height: 1px;
    background-color: rgba(255, 255, 255, 0.3);
    width: 100%;
}

.audioErrors {
    display: flex;
    flex-direction: column;
    padding: .5rem;
    background-color: rgba(var(--sf-red-rgb), 45%);
    border-radius: 12px;
}

.requestInfo {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
}

.requestActions {
    display: flex;
    flex-direction: row;
}

.requestIcon {
    display: flex;
    height: 14px;
    width: 14px;
    min-height: 14px;
    min-width: 14px;
}

.declineButton {
    padding: .125rem .5rem;
    border-radius: 6px 0 0 6px;
    background-color: var(--sf-red);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.acceptButton {
    padding: .125rem .5rem;
    border-radius: 0;
    background-color: var(--sf-green);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.acceptButton.rounded {
    border-radius: 0 6px 6px 0;
}

.acceptPinButton {
    padding: .125rem .5rem;
    border-radius: 0 6px 6px 0;
    background-color: var(--sf-yellow);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

@media screen and (min-width: 390px) {
    .container {
        flex-direction: row;
    }
}