.sidenav {
    transition: .2s ease-in-out;
    height: 100%;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    /* background-color: rgb(246,246,246); */
    background-color: var(--sidenav-color);
    color: var(--text-color);
    z-index: 99;
    border-right: 1px solid rgba(var(--text-color-rgb), 12%);
    position: -webkit-sticky;
    position: sticky;
    top: 0;
}

.logo {
    width: 7rem;
    height: 42px;
    object-fit: contain;
    cursor: pointer;
}

.logoMini {
    width: 48px;
    height: 42px;
    object-fit: contain;
    cursor: pointer;
}

.close {
    min-width: 90px;
    max-width: 90px;
    padding: 1.5rem 0rem 0rem 0rem;
    transition: .2s ease-in-out;
    z-index: 12;
}

.open {
    min-width: 300px;
    max-width: 300px;
    padding: 1.5rem 1rem 0rem 1rem;
    transition: .2s all ease-in-out;
}

.header {
    position: relative;
    display: flex;
    align-items: center;
}

.open>.header {
    display: flex;
    justify-content: space-between;
}

.close>.header {
    display: flex;
    justify-content: center;
}

.close>.content {
    padding: 1rem;
}

.open>.content {
    padding: 1rem 0rem;
}

.close>.content>button {
    display: flex;
    justify-content: center;
}

.close>.content>button {
    width: 100%;
    margin: 0;
}

.closeButton {
    width: 24px;
    height: 24px;
    background-color: transparent;
    border: 1px solid rgba(var(--text-color-rgb), 12%);
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    z-index: 99;
}

.close .closeButton {
    position: absolute;
    top: 15%;
    right: -12px;
    transform: rotate(180deg);
    background: #FCFCFC;
    z-index: 99;
}

.sidenav>.content {
    display: flex;
    flex: 1;
    flex-direction: column;
    gap: 0.4rem;
    padding-top: 3rem;
}

.sidenav>.content>a {
    background-color: transparent;
    padding: 1rem;
    color: var(--text-color);
    text-decoration: none;
    border-radius: 10px;
}

.sidenav>.content>.navLink {
    background-color: transparent;
    padding: 1rem;
    color: var(--text-color);
    text-decoration: none;
    border-radius: 10px;
    border: none;
}

.spacer {
    flex-grow: 1;
    flex-shrink: 1;
}

.navLink {
    background-color: transparent;
    display: flex !important;
    align-items: center !important;
    gap: 0.5rem;
    font-size: 1rem;
    font-weight: 600;
    font-family: var(--primary-font);
    text-decoration: none;
    color: rgba(var(--text-color-rgb), 100%);
    cursor: pointer;
    position: relative;
}

.active {
    color: var(--primary) !important;
    background: rgba(var(--primary-rgb), 10%) !important;
    border-radius: .75rem !important;
    font-weight: 800;
}

.bottomNavLink {
    background-color: transparent;
    display: flex !important;
    align-items: center !important;
    gap: 0.5rem;
    font-size: 1rem;
    font-weight: 600;
    font-family: var(--primary-font);
    text-decoration: none;
    color: rgba(var(--text-color-rgb), 100%);
    cursor: pointer;
    position: relative;
    border-radius: 0 !important;
    border: none;
    padding: 1rem;
    border-top: 1px solid rgba(var(--text-color-rgb), 12%) !important;
}

.divider {
    height: 1px;
    background: rgba(var(--text-color-rgb), 12%);
    width: 100%;
}

.userContainer {
    background: none;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    cursor: pointer;
}

.userAvatar {
    object-fit: cover;
    padding: 3px;
    border-radius: 16px;
    height: 32px;
    width: 32px;
    border: 1.26px solid var(--secondary);
    background: rgba(var(--text-color-rgb), 12%);
}

.userName {
    font-family: var(--primary-font);
    font-size: 1rem;
    font-weight: 600;
    color: var(--text-color)
}

.close .userName {
    display: none;
}

.login {
    color: var(--primary);
}

.iconButtons {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 1rem;
}

.iconButton {
    width: 50px;
    height: 50px;
    min-width: 50px;
    min-height: 50px;
    border: none;
    background-color: transparent;
    margin: 0 .3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    color: black;
}


.badge {
    position: absolute;
    top: 0px;
    right: 0px;
    background-color: var(--secondary);
    color: white;
    font-weight: 700;
    font-size: 0.75rem;
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    border: 2px solid white;
}

.iconBadge {
    position: absolute;
    top: .5rem;
    left: 1.5rem;
    background-color: var(--secondary);
    color: white;
    font-weight: 700;
    font-size: 0.75rem;
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    border: 2px solid white;
}

.userButton {
    display: flex;
    background-color: transparent;
    border: none;
    border-top: 1px solid rgba(var(--text-color-rgb), 12%);
    padding: 1rem .5rem;

}

@media screen and (max-width: 768px) {
    
    .sidenav {
        max-width: 100%;
        height: --webkit-fill-available;
        min-height: 70px;
        background-color: var(--sidenav-color);
        border-right: 0px solid;
        position: sticky;
        top: 0;
        border-bottom: 0px solid;
    }

    .content {
        position: relative;
        height: 0px;
        top: 0px;
        left: 0;
        right: 0;
        background-color: var(--sidenav-color);
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        align-items: center;
        transition: .2s all ease-in-out;
    }

    .content > * {
        width: 100%;
    }

    .open>.content {
        padding: 1rem;
        overflow-y: scroll;
        justify-content: flex-start;
        visibility: visible;
        height: 100%;
        transition: .2s all ease-in-out;
    }

    .close>.header {
        justify-content: space-between;
    }

    .header {
        display: flex;
        height: 70px;
        min-height: 70px;
        justify-content: space-between;
        align-items: center;
        max-width: 100%;
        padding: 0 1rem;
        border-bottom: 1px solid rgba(var(--text-color-rgb), 12%);
    }

    .logo {
        width: 7rem;
    }

    .close {
        width: 100%;
        height: 70px;
        position: sticky;
        top: 0;
        display: flex;
        justify-content: center;
        padding: 0;
    }

    .open {
        position: fixed;
        top: 0;
        width: 100%;
        padding: 0;
    }

    .close>.content> *{
        display: none !important;
    }

    .close>.content {
        padding: 0 1rem;
    }

}

@media (hover: hover) {
    .sidenav>.content>button:hover {
        color: var(--primary);
    }

    .logout:hover {
        color: var(--secondary) !important;
    }

    .userContainer:hover>.userName {
        color: var(--secondary) !important;
    }
}