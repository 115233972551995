.container {
  display: flex;
  flex-direction: row;
  gap: .5rem;
  align-items: center;
}


.check {
  padding: .3rem;
  border: 1px solid rgba(var(--text-color-rgb), 10%);
  width: 2rem;
  height: 2rem;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: rgba(var(--text-color-rgb), 80%);
}
