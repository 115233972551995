.container {
  --not-selected-color: rgba(var(--text-color-rgb), 72%);
}

.wrapper {
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 1rem;
  overflow: 'hidden'
}

.notSelected {
  text-decoration: none;
  color: var(--not-selected-color);
}

@media (hover: hover) {
  .notSelected:hover {
    color: var(--primary);
  }
}

@media screen and (max-width: 540px) {
  .wrapper {
    flex-direction: column;
    align-items: flex-start;
  }
}
