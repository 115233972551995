.material {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  width: 100%;
  border: 1px solid rgba(var(--text-color-rgb), 12%);
  border-radius: 8px;
  padding: .7rem;
  align-items: center;
  justify-content: space-between;
  background-color: var(--background-color);
}

.info {
  display: flex;
  flex-direction: column;
  max-width: 80%;
}

.name {
  font-size: 1rem;
  font-weight: 800;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  height: 1.5rem;
  text-overflow: ellipsis;
  overflow-wrap: anywhere;
}

.size {
  font-weight: 700;
  font-size: 0.75rem;
  color: rgba(var(--text-color-rgb), 48%);
}

.close {
  border: 1.33px solid rgba(var(--secondary-rgb), 32%);
  background-color: rgba(var(--secondary-rgb), 4%);
  background-color: transparent;
  border-radius: 10px;
  cursor: pointer;
  color: var(--secondary);
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.download {
  border: 1.33px solid rgba(var(--primary-rgb), 32%);
  background-color: rgba(var(--primary-rgb), 4%);
  border-radius: 8px;
  cursor: pointer;
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--sf-light-green);
}
