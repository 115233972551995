.container {
    display: flex;
    flex-direction: column;
    padding: 0;
    min-height: 100dvh;
    max-height: 100dvh;
    max-width: 100vw;
    background-color: #212121;
    width: 100%;
    height: 100%;
    color: var(--background-color);
    position: relative;
}

.header {
    display: flex;
    flex-direction: column;
    gap: .25rem;
}

.section {
    display: flex;
    flex-direction: column;
    padding: 1rem;
    width: 100%;
    height: 100%;
    flex: 1;
    color: var(--background-color);
    gap: .5rem;
    position: relative;
}

.roomError {
    display: flex;
    color: var(--background-color);
    width: 100%;
    height: 100%;
    min-height: 80dvh;
    justify-content: center;
    align-items: center;
}

.loading {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
    min-height: 80dvh;
    justify-content: center;
    align-items: center;
    gap: .5rem;
}