.container {
  width: 100%;
  padding: 1.5rem;
  border: 1px solid rgba(var(--text-color-rgb), 12%);
  border-radius: 16px;
  background-color: var(--background-color);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 1rem;
  min-width: 230px;
  flex: 1 1;
}


.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.iconContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  border-radius: 48px;
}

.icon {
  width: 24px;
  height: 24px;
}

.increment {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  border-radius: 10px;
  background-color: var(--background-secondary-color);
  justify-content: flex-end;
  padding: .4rem 0.7rem;
  gap: .2rem;
}

.incrementLeft {
  display: flex;
  flex-direction: column;
  text-align: end;
}

.incrementValue {
  font-weight: 800;
  font-size: .875rem;
}

.incrementLabel {
  font-weight: 800;
  font-size: 0.625rem;
  color: rgba(var(--text-color-rgb), 40%);
  text-transform: uppercase;
  line-height: 0.5rem;
}

.incrementIcon {
  width: 16px;
  height: 16px;
  color: var(--sf-light-green);
}

.incrementIcon.reverse {
  transform: rotate(180deg);
  color: var(--sf-red);
}

.equalIcon {
  width: 16px;
  height: 16px;
  color: var(--sf-yellow);
}

.content {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  text-align: start;
}

.value {
  font-weight: 800;
  font-size: 1.75rem;
}

.label {
  font-weight: 800;
  font-size: .875rem;
  color: rgba(var(--text-color-rgb), 40%);
  text-transform: uppercase;
}
