.container {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}

.label {
  font-weight: 800;
  color: rgba(var(--text-color-rgb), 40%);
}

.actions {
  display: flex;
  flex-direction: row;
  gap: .5rem;
}

.delete {
  padding: .3rem;
  border: 1px solid rgba(var(--text-color-rgb), 10%);
  width: 2rem;
  height: 2rem;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: rgba(var(--text-color-rgb), 80%);
}

.delete {
  background-color: white
}


.editor {
  padding: 0;
  border: none;
  background-color: transparent;
  font-size: 1rem;
  font-family: "Manrope";
  width: 100%;
  border-radius: 0;

}

.editorContent {
  padding: 0;
  display: flex;
  align-items: center;
}

.label,
.actions {
  padding-top: 28px;
}

@media(hover: hover) {
  .delete:hover {
    background-color: rgba(var(--text-color-rgb), 10%);
  }
}

.left {
  display: flex;
  align-items: center;
  gap: .5rem;
  flex-direction: row;
  width: 100%;
}

@media (max-width: 540px) {

  .container {
    position: relative;
  }

  .actions {
    position: absolute;
    top: 0;
    right: 0;
    padding-top: 0;
  }

  .left {
    padding-top: 12px;
  }

}


@media (max-width: 472px) {


  .left {
    padding-top: 48px;
  }

}