.container {
    display: flex;
    height: 400px;
    width: 400px;
    flex-direction: column;
    position: relative;
    z-index: 1;
}

.gameboard {
    flex-direction: row;
    display: flex;
    width: 100%;
    height: 100%;
    flex-wrap: wrap;
    gap: 0.5rem;
}

.square {
    display: flex;
    width: 30%;
    height: auto;
    align-items: center;
    justify-content: center;
    position: relative;
    background-color: rgba(var(--primary-rgb), 12%);
    border-radius: 12px;
    cursor: pointer;
    border: none;
}

.square.winning {
    border: 2px solid var(--primary);
}

.square.losing {
    border: 2px solid var(--secondary);
}


.value {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    font-size: 6rem;
    font-weight: 800;
    font-family: var(--primary-font);
}

.result {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
    padding: 1rem 0;
    height: 50px;
}

.actions {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
    align-items: center;
    padding: 1rem 0;
}
