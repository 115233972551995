.container {
  padding: 9px;
  border-radius: 32px;
  background-color: var(--background-color);
  cursor: pointer;
  /* width: 48px;
  height: 48px;
  min-width: 48px;
  min-height: 48px; */
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon {
  width: 30px;
  height: 30px;
}
