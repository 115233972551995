.cardHeader {
    display: flex;
    flex-direction: row;
    width: 100%;
    border-bottom: 1px solid rgba(var(--text-color-rgb), 12%);
    padding: 1rem 1.5rem;
    align-items: center;
    gap: .7rem;
}

.icon {
    width: 32px;
    height: 32px;
    min-width: 32px;
    min-height: 32px;
    border-radius: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.icon.communication>img {
    width: 36px;
    height: 36px;
    object-fit: cover;
    border-radius: 18px;
    padding: 1px;
    border: 1px solid var(--secondary);
}

.info {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.title {
    font-size: 1rem;
    font-weight: 700;
    color: var(--text-color)
}

.subtitle {
    font-size: 0.875rem;
    font-weight: 500;
    color: rgba(var(--text-color-rgb), 72%);
    display: flex;
    align-items: center;
    gap: .2rem;
}

.clockIcon {
    height: 12px;
    width: 12px;
}

.clockIcon>g>path {
    stroke: rgba(var(--text-color-rgb), 72%)
}

.date {
    font-size: 0.875rem;
    font-weight: 700;
    color: rgba(var(--text-color-rgb), 32%);
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    white-space: nowrap;
    gap: 0.4rem;
}

.date>.clockIcon {
    width: 20px;
    height: 20px;
}

.date>.clockIcon>g>path {
    stroke: rgba(var(--text-color-rgb), 32%);
}

.content {
    padding: 1.5rem;
}

.description {
    font-size: 0.875rem;
    padding-top: 1rem;
    color: var(--text-color);
}

.description.communication {
    padding: 0;
    white-space: pre-line;
}