.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  margin: 0 auto;
  background-color: var(--background-secondary-color);
}

.section {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: fit-content;
  align-items: center;
  padding: 2rem 0;
}

.sectionInner {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1440px;
  padding: 0 3rem;
  gap: 1rem;
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.title {
  font-size: 2rem;
  font-weight: 800;
}

.top {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 1rem;
}

.content {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
}

.students {
  display: flex;
  flex-direction: column;
  gap: .5rem;
  width: 100%;
}

.cardTitle {
  font-weight: 800;
  font-size: 1.125rem;
  margin-bottom: .5rem;
}

.student {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: .5rem;
  justify-content: space-between;
  border: 1px solid rgba(var(--text-color-rgb), 12%);
  border-radius: 8px;
  padding: .5rem;
  align-items: center;
  height: 64px;
  background-color: var(--background-color);
  cursor: pointer;
  user-select: none;
}

.student>div:first-child {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: .2rem;
}

.picture {
  width: 40px;
  height: 40px;
  border-radius: 40px;
  padding: 4px;
}

.name {
  font-weight: 700;
  font-size: 1rem;
  color: rgba(42, 42, 42, 80%);
  text-align: start;
}

.studentStatus {
  color: var(--sf-light-green);
  font-weight: 800;
  font-size: 0.875rem;
}

.studentStatus.notCompleted {
  color: rgba(181, 184, 190, 1);
}

.studentStatus.failed {
  color: var(--sf-red);
}


.recap {
  display: flex;
  flex-direction: column;
  gap: .5rem;
  width: 100%;
}

.chartLabels {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: .5rem;
}

.chartData {
  display: flex;
  flex-direction: column;
  width: 30%;
}

.chartCell {
  background: rgba(var(--text-color-rgb), 4%);
  padding: .5rem 1rem;
  border-radius: 8px;
  overflow: hidden;
  flex-grow: 1;
}

.chartRow {
  display: flex;
  flex-direction: row;
  gap: 1rem;
}

.chartColor {
  height: 14px;
  width: 14px;
  border-radius: 30px;
}

.chartColumn {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.chartDataTitle {
  font-family: var(--primary-font);
  font-weight: 800;
}

.chartDataSubitle {
  font-family: var(--primary-font);
  font-weight: 600;
  font-size: 0.8rem;
}

.circularChartColumn {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.innerTest {
  display: flex;
  flex-direction: column;
  gap: .5rem;
  width: 100%;
}

.answerContainer {
  display: flex;
  flex-direction: column;
}

.answerWrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  padding: 0 1rem;
  margin-bottom: 1rem;
}

.answer {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  padding: .8rem 1rem;
  align-items: center;
  width: 100%;
  border: 1px solid rgba(var(--text-color-rgb), 12%);
  border-radius: 14px;
  min-height: 60px;
  flex-grow: 1;
  max-width: 66%;
}

.left {
  display: flex;
  flex-direction: row;
  gap: 1rem;
}

.correctLabel {
  color: var(--sf-light-green);
  font-weight: 800;
  display: flex;
  align-items: center;
}

.wrongLabel {
  color: rgba(var(--text-color-rgb), 40%);
  font-weight: 800;
  display: flex;
  align-items: center;
}

.answerStats {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  flex-grow: 1;
  gap: 1rem;
  min-width: 200px;
}

.percentage {
  font-size: 1.125rem;
  color: var(--sf-gray);
  font-weight: 800;
  min-width: 48px;
}

.percentage.correct {
  color: var(--sf-light-green);
}

.percentage.wrong {
  color: var(--sf-red);
}


.barContainer {
  width: 100%;
  flex-grow: 1;
}

.bar {
  min-height: 40px;
  background-color: var(--sf-gray);
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
}

.bar.correct {
  background-color: var(--sf-light-green);
}

.bar.wrong {
  background-color: var(--sf-red);
}

.chevron {
  min-width: 40px;
  min-height: 40px;
  border-radius: 12px;
  border: 1.5px solid rgba(var(--sf-light-green-rgb), 20%);
  color: var(--sf-light-green);
  display: flex;
  justify-content: center;
  align-items: center;
  transition: .2s all ease;
  cursor: pointer;
}

.chevron.disabled {
  border: 1.5px solid rgba(var(--sf-gray-rgb), 40%);
  color: rgba(var(--sf-gray-rgb), 40%);
  cursor: default;
}

.chevron.open {
  border: 1.5px solid rgba(var(--sf-red-rgb), 20%);
  color: var(--sf-red);
}

.chevron>.closed {
  transform: rotate(180deg);
}

.chevron>.open {
  transform: rotate(0deg);
}

.answerStudents {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: .5rem;
  padding: 1rem;
  background-color: var(--background-secondary-color);
  overflow-y: scroll;
}

.words {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  row-gap: 1rem;
  column-gap: .3rem;
  padding: 1rem;
  font-size: 1.125rem;
  font-weight: 800;
}

.word.hidden {
  padding: .2rem;
  border-radius: 8px;
  border: 1px solid rgba(var(--text-color-rgb), 12%);
  text-transform: uppercase;
  font-size: 0.75rem;
}

.wordStats {
  display: flex;
  flex-direction: column;
  gap: .5rem;
  justify-content: space-between;
  flex-grow: 1;
}

.wordStat {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: .5rem;
}

.studentWordContainer {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: space-between;
  text-align: start;
}

.studentWord {
  font-weight: 700;
  color: var(--sf-light-green)
}

.studentWord.wrong {
  color: rgba(var(--text-color-rgb), 80%)
}

.successIcon {
  width: 24px;
  height: 24px;
}

.successIcon>path {
  fill: none !important;
}

.errorIcon {
  width: 24px;
  height: 24px;
}

.errorIcon path {
  fill: currentColor !important;
}

.badges {
  display: flex;
  flex-direction: column;
  gap: 2px;
}

@media(hover:hover) {
  .student:hover {
    border: 1px solid var(--sf-light-green);
  }

  .chevron:not(.disabled):hover {
    border: 1.5px solid rgba(var(--sf-light-green-rgb), 80%);
  }

  .chevron.open:hover {
    border: 1.5px solid rgba(var(--sf-red-rgb), 80%);
  }
}


@media screen and (max-width: 1024px) {

  .answerWrapper {
    gap: 1rem;
  }

  .barContainer {
    display: none;
  }

  .answerStats {
    flex-grow: 0;
    min-width: 0;
  }

  .answer {
    max-width: 100%;
  }

  .student {
    width: 100% !important;
  }
}

@media screen and (max-width: 540px) {

  .sectionInner {
    padding: 0 2rem;
  }
}