.container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
  max-width: 1440px;
  padding: 0 3rem;
}


.content {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 1.25rem;
}

.leftColumn {
  flex: 1 1 30%;
  width: 340px;
  height: fit-content;
  border: 1px solid rgba(var(--text-color-rgb), 12%);
  border-radius: 14px;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  align-items: center;
  justify-content: center;
  background-color: var(--background-color);
}

.rightColumn {
  flex: 1 1 70%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.card {
  border: 1px solid rgba(var(--text-color-rgb), 12%);
  border-radius: 14px;
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: center;
  background-color: var(--background-color);
}

.profile {
  border: 1px solid rgba(var(--text-color-rgb), 12%);
  border-radius: 14px;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  align-items: center;
  justify-content: center;
  background-color: var(--background-color);
}

.teacher {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
}

.profilePic {
  width: 100px;
  border-radius: 50px;
}

.username {
  font-weight: 800;
  font-size: 1.125rem;
}

.cardTitle {
  font-weight: 800;
  font-size: 1.5rem;
}

.inputs {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 1rem;
}

.row>div {
  flex: 1 1;
}


.textArea {
  border: 1px solid rgba(var(--text-color-rgb), 12%);
  padding: 1rem;
  width: 100%;
  border-radius: 14px;
  background-color: transparent;
  resize: none;
  font-family: "Manrope";
  font-size: 1rem;
  font-weight: 600;
  flex: 1 1;
  background-color: transparent;
  color: var(--text-color);
  min-height: 144px;
}

.textArea:focus {
  outline: none;
}

.textArea::placeholder {
  color: rgba(var(--text-color-rgb), 12%)
}


.dialog {
  width: 100%;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.editPictureButton {
  background-color: var(--tertiary);
}

.editPictureDropzone {
  background-color: rgba(var(--tertiary-rgb), 8%);
  border: 2px dashed #4CB4FF32;
  border-radius: 12px;
  padding: 2rem 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  flex: 1;
}

.editPictureLabel {
  font-family: var(--primary-font);
  font-weight: 500;
  font-size: 1rem;
  color: rgba(var(--text-color-rgb), 72%);
  max-width: 200px;
  text-align: center;
}

@media screen and (max-width: 1024px) {}

@media screen and (max-width: 900px) {
  .sectionInner {
    flex-direction: column;
  }

  .leftColumn {
    width: 100%;
  }
}

@media (hover:hover) {}
