.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  margin: 0 auto;
  background-color: var(--background-secondary-color);
}

.section {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: fit-content;
  align-items: center;
  padding: 2rem 0;
}

.sectionInner {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1440px;
  padding: 0 3rem;
  gap: 1rem;
}

.content {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.header {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  align-items: center;
  width: 100%;
}

.add {
  flex-grow: 1;
  display: flex;
  justify-content: flex-end;
}

.modules {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.module {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
  background-color: white;
}

.moduleTitle {
  display: flex;
  flex-direction: column;
}

.exercises {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: stretch;
  gap: 1rem;
}

/* .exercises:nth-child(1) {
  padding-right: .5rem;
} */

/* .exercise:nth-child(3n+2) {
  padding: 0 1rem;
} */

.exercise {
  flex-basis: 33.33%;
  flex-grow: 1;
  min-width: 300px;
}


.emptyList {
  display: flex;
  flex-direction: row;
  align-items: center;
}

@media (max-width: 768px) {
  /* .header {
    flex-direction: column;
  } */

  .sectionInner {
    padding: 0 2rem;
  }
}

@media (max-width: 540px) {
  /* .header {
    flex-direction: column;
  } */

  .sectionInner {
    padding: 0 1rem;
  }
}
